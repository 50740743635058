import { Application } from '../../../../../../../../../../types/api';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import {
  defaultApplicantName,
  NON_PROFIT_ORGANIZATION_TYPES,
} from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  companyDocuments: { religiousOrganizationAttendance },
  nonProfitOrganizationType,
} = loanFormModel.formField;

export class ReligiousOrganizationAttendanceValidation
  implements ValidationSchema
{
  public name = religiousOrganizationAttendance.name;
  public label = religiousOrganizationAttendance.getLabel(defaultApplicantName);
  public dependsOn: string[] = [nonProfitOrganizationType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    nonProfitOrganizationType: Application['non_profit_organization_type'],
    schema
  ) {
    const required =
      nonProfitOrganizationType === NON_PROFIT_ORGANIZATION_TYPES.FBO;
    schema = FileNameValidationSchema(
      religiousOrganizationAttendance.name,
      required
    );
    if (required) schema = schema.required('This document is required');
    return schema;
  }
}
export default new Validation(new ReligiousOrganizationAttendanceValidation());
