import { isYes } from '../../../../../../../components/loan_application/utils/helpers';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { taxDocuments } from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { applicantFiledLastYearTaxReturns } = taxDocuments;

class HasApplicantFiledTRLastYear implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const hasFiled = application[applicantFiledLastYearTaxReturns.name];
    return hasFiled !== null && hasFiled !== undefined && isYes(hasFiled);
  }
}

export default new HasApplicantFiledTRLastYear();
