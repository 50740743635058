import { Application } from '../../../../../../../../../../types/api';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { NON_PROFIT_ORGANIZATION_TYPES } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const { priorYearBudgets } = loanFormModel.formField.hoaDocuments;
const { nonProfitOrganizationType } = loanFormModel.formField;

export class PriorYearBudgetsValidation implements ValidationSchema {
  public name = priorYearBudgets.name;
  public label = '';
  public dependsOn: string[] = [nonProfitOrganizationType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    nonProfitOrganizationType: Application['non_profit_organization_type'],
    schema
  ) {
    if (nonProfitOrganizationType === NON_PROFIT_ORGANIZATION_TYPES.HOA)
      return FileNameValidationSchema(priorYearBudgets.name, true).required(
        'This document is required'
      );
    return FileNameValidationSchema(priorYearBudgets.name, false).notRequired();
  }
}
export default new Validation(new PriorYearBudgetsValidation());
