import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import * as Yup from 'yup';
import { getEmailValidationSchema } from '../../../../../../../utils/validation';
import PrincipalTypeValidation from './PrincipalTypeValidation';
import PrincipalTrustNameValidation from './PrincipalTrustNameValidation';
import PrincipalTrustTINValidation from './PrincipalTrustTINValidation';
import PrincipalTrustYearEstablishedValidation from './PrincipalTrustYearEstablishedValidation';
import PrincipalOccupationValidation from './PrincipalOccupationValidation';
import PrincipalOtherOccupationValidation from './PrincipalOtherOccupationValidation';
import PrincipalAddressValidation from './PrincipalAddressValidation';
import PrincipalBirthdateValidation from './PrincipalBirthdateValidation';
import PrincipalIsUSCitizenValidation from './PrincipalIsUSCitizenValidation';
import PrincipalNonUSPersonIdNumberValidation from './PrincipalNonUSPersonIdNumberValidation';
import PrincipalNonUSPersonIssuanceCountryValidation from './PrincipalNonUSPersonIssuanceCountryValidation';
import PrincipalSsnValidation from './PrincipalSsnValidation';
import PrincipalPhoneNumberValidation from './PrincipalPhoneNumberValidation';
import PrincipalCityValidation from './PrincipalCityValidation';
import PrincipalStateValidation from './PrincipalStateValidation';
import PrincipalCountryValidation from './PrincipalCountryValidation';
import PrincipalMortgagePaymentValidation from './PrincipalMortgagePaymentValidation';
import PrincipalRentValidation from './PrincipalRentValidation';
import PrincipalLiquidAssetsValidation from './PrincipalLiquidAssetsValidation';
import PrincipalPGAgreementValidation from './PrincipalPGAgreementValidation';
import PrincipalAnnualGrossIncomeValidation from './PrincipalAnnualGrossIncomeValidation';
import ApplicantSameAsPrincipalValidation from './ApplicantSameAsPrincipalValidation';
import PrincipalIsPGValidation from './PrincipalIsPGValidation';
import PrincipalPercentOwnedValidation from './PrincipalPercentOwnedValidation';
import PrincipalFirstNameValidation from './PrincipalFirstNameValidation';
import PrincipalLastNameValidation from './PrincipalLastNameValidation';
import PrincipalZipValidation from './PrincipalZipValidation';
import PrincipalIdDocumentFileNameValidation from './PrincipalIdDocumentFileNameValidation';
import PrincipalRentOrOwnValidation from './PrincipalRentOrOwnValidation';
import PropertyOwnerPercentOwnershipValidation from './PropertyOwnerPercentOwnershipValidation';

const {
  formField: {
    // Principal
    principalMiddleInitial,

    principalEmailAddress,
    // Personal Guarantor

    principalIdDocument,
  },
} = loanFormModel;

export class PrincipalsValidation implements ValidationSchema {
  public name = 'principals';
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(schema: Yup.ArraySchema<Yup.ObjectSchema<any>>) {
    const validations = [
      PrincipalTypeValidation,
      PrincipalTrustNameValidation,
      PrincipalTrustTINValidation,
      PrincipalTrustYearEstablishedValidation,
      PrincipalOccupationValidation,
      PrincipalOtherOccupationValidation,
      PrincipalAddressValidation,
      PrincipalBirthdateValidation,
      PrincipalIsUSCitizenValidation,
      PrincipalNonUSPersonIdNumberValidation,
      PrincipalNonUSPersonIssuanceCountryValidation,
      PrincipalSsnValidation,
      PrincipalPhoneNumberValidation,
      PrincipalPercentOwnedValidation,
      PrincipalFirstNameValidation,
      PrincipalLastNameValidation,
      PrincipalCityValidation,
      PrincipalStateValidation,
      PrincipalZipValidation,
      PrincipalCountryValidation,
      PrincipalIsPGValidation,
      ApplicantSameAsPrincipalValidation,
      PrincipalAnnualGrossIncomeValidation,
      PrincipalLiquidAssetsValidation,
      PrincipalRentValidation,
      PrincipalRentOrOwnValidation,
      PrincipalMortgagePaymentValidation,
      PrincipalPGAgreementValidation,
      PropertyOwnerPercentOwnershipValidation,

      // TODO: Uncomment these back when CRB requires it
      // EthnicityValidation,
      // SubEthnicityValidation,
      // OtherEthnicityValidation,
      // SexGenderValidation,
      // RaceValidation,
      // SubRaceValidation,
      // OtherRaceValidation,
    ];

    const validationSchema: Record<string, any> = validations.reduce(
      (schema, validation) => {
        schema[validation.name] = validation.buildSchema();
        return schema;
      },
      {}
    );

    validationSchema[principalEmailAddress.name] = getEmailValidationSchema(
      principalEmailAddress
    );
    validationSchema[principalMiddleInitial.name] = Yup.string().nullable();
    validationSchema.documents = Yup.object().shape({
      [principalIdDocument.name]: Yup.object().shape({
        files: Yup.array().of(
          Yup.object().shape({
            file_name: PrincipalIdDocumentFileNameValidation.buildSchema(),
          })
        ),
      }),
    });

    return schema.of(Yup.object().shape(validationSchema)).required();
  }
}
export default new Validation(new PrincipalsValidation());
