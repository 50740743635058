import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  IsBetweenMidAprilMidMay,
  IsBetweenMidAugMidOct,
  IsBetweenMidMayMidAug,
  RulesetResult,
  HasApplicantFiledTRLastYear,
  IsBetweenMidNovAndDec,
  IsBetweenMidOctMidNov,
} from '../rules';

// @see https://sunstonecreditinc.atlassian.net/browse/SUNENG-2200
// Rules are separated by express/non-express loans. However, the rules are the same for both.
class TRLastYearRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    if (HasApplicantFiledTR.evaluate(data)) {
      const isBetweenMidAprilAndMidOct = [
        IsBetweenMidAprilMidMay,
        IsBetweenMidMayMidAug,
        IsBetweenMidAugMidOct,
      ].some((Is) => Is.evaluate(data));

      const IsBetweenMidOctAndDec = [
        IsBetweenMidOctMidNov,
        IsBetweenMidNovAndDec,
      ].some((Is) => Is.evaluate(data));

      if (isBetweenMidAprilAndMidOct)
        return HasApplicantFiledTRLastYear.evaluate(data)
          ? RulesetResult.REQUIRED
          : RulesetResult.HIDDEN;
      else if (IsBetweenMidOctAndDec) return RulesetResult.REQUIRED;
    }

    return RulesetResult.HIDDEN;
  };
}

export default new TRLastYearRuleset();
