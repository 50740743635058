import { RELATED_PARTY_TYPES } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalType, principalOccupation },
} = loanFormModel;

export class PrincipalOccupationValidation implements ValidationSchema {
  public name = principalOccupation.name;
  public label = principalOccupation.label;
  public dependsOn: string[] = [principalType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, schema) {
    if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
      return schema.required(principalOccupation.requiredErrorMsg);
    }
    return schema.notRequired();
  }
}
export default new Validation(new PrincipalOccupationValidation());
