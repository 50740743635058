import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  IsBetweenJanAndMidFeb,
  isExpressLoan,
  RulesetResult,
} from '../rules';
import IsBusinessEstablishedEqualTo from '../rules/IsBusinessEstablishedEqualTo';
import IsBusinessEstablishedLessThan from '../rules/IsBusinessEstablishedLessThan';

class FinancialStatementLastYearQ3Ruleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const businessEstablishedTwoYearsAgo =
      IsBusinessEstablishedEqualTo.setYears(2).evaluate(data);
    const businessEstablishedLessThanTwoYearsAgo =
      IsBusinessEstablishedLessThan.setYears(2).evaluate(data);
    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);
    const isBetweenJanAndMidFeb = IsBetweenJanAndMidFeb.evaluate(data);

    if (
      !hasApplicantEverFiledTR &&
      (businessEstablishedTwoYearsAgo || businessEstablishedLessThanTwoYearsAgo)
    ) {
      if (isBetweenJanAndMidFeb)
        return businessEstablishedTwoYearsAgo
          ? RulesetResult.REQUIRED
          : RulesetResult.OPTIONAL;
      return RulesetResult.HIDDEN;
    }

    if (isBetweenJanAndMidFeb && hasApplicantEverFiledTR)
      return isExpressLoan.evaluate(data)
        ? RulesetResult.OPTIONAL
        : RulesetResult.REQUIRED;
    return RulesetResult.HIDDEN;
  };
}

export default new FinancialStatementLastYearQ3Ruleset();
