import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import FileNameValidationSchema from '../FileNameValidationSchema';
import { Validation } from '../Validation';
import { powerSoldToSingleUserCondition } from '../../components/loan_application/utils/helpers';

const { ppaLeaseFinancialProjections } =
  loanFormModel.formField.solarSystemDocuments;
const { systemPowerUsage, systemPowerSingleOrMultipleUsers } =
  loanFormModel.formField;

export class PpaLeaseFinancialProjectionsValidation
  implements ValidationSchema
{
  public name = ppaLeaseFinancialProjections.name;
  public label = '';
  public dependsOn: string[] = [
    systemPowerUsage.name,
    systemPowerSingleOrMultipleUsers.name,
  ];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    powerUsage: Application['solar_system_power_usage'],
    singleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
    schema
  ) {
    if (powerSoldToSingleUserCondition(powerUsage, singleOrMultipleUsers))
      return FileNameValidationSchema(ppaLeaseFinancialProjections.name, true);
    return FileNameValidationSchema(ppaLeaseFinancialProjections.name, false);
  }
}
export default new Validation(new PpaLeaseFinancialProjectionsValidation());
