import loanFormModel from '../../form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  formField: { systemPropertyOwnershipStatus },
} = loanFormModel;

export class PropertyOwnershipStatusValidation implements ValidationSchema {
  public name: string = systemPropertyOwnershipStatus.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: true,
  };
  public nullable = true;
}
export default new Validation(new PropertyOwnershipStatusValidation());
