import { Application } from '../../../../../../../../../../types/api';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import {
  BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT,
  defaultApplicantName,
} from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  companyDocuments: { nonProfitBylawsCopy },
  systemPowerCompanyType,
  isPG,
} = loanFormModel.formField;

export class NonProfitBylawsCopyValidation implements ValidationSchema {
  public name = nonProfitBylawsCopy.name;
  public label = nonProfitBylawsCopy.getLabel(defaultApplicantName);
  public dependsOn: string[] = [systemPowerCompanyType.name, isPG.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    businessTypeForProfitOrNonProfit: Application['business_type_for_profit_or_non_profit'],
    schema
  ) {
    const required =
      businessTypeForProfitOrNonProfit ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.NON_PROFIT;
    schema = FileNameValidationSchema(nonProfitBylawsCopy.name, required);
    if (required) schema = schema.required('This document is required');
    return schema;
  }
}
export default new Validation(new NonProfitBylawsCopyValidation());
