import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import loanFormModel from '../../form_models/loanFormModel';
import BusinessOwnershipStatusRuleset from '../../business/ruleset/BusinessOwnershipStatusRuleset';
import * as yup from 'yup';

const {
  businessOwnershipStatus,
  businessTypeForProfitOrNonProfit,
  borrowerGrossRevenueLatest,
} = loanFormModel.formField;

export class BusinessOwnershipStatusValidation implements ValidationSchema {
  public name = businessOwnershipStatus.name;
  public label = businessOwnershipStatus.label;
  public dependsOn: string[] = [
    businessTypeForProfitOrNonProfit.name,
    borrowerGrossRevenueLatest.name,
  ];
  public type = 'array';
  public required = {
    message: businessOwnershipStatus.requiredErrorMsg,
    value: false,
  };
  public nullable = true;
  public ruleset = BusinessOwnershipStatusRuleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const schema = args[this.dependsOn.length];
    const required = !!(result & RulesetResult.REQUIRED);

    if (required) {
      return schema
        .of(yup.string())
        .min(1, businessOwnershipStatus.requiredErrorMsg)
        .required();
    }
    return schema;
  }
}
export default new Validation(new BusinessOwnershipStatusValidation());
