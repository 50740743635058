import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import { isPropertyCompany, isCoreOrExpressPlusLoan } from './helpers';

const {
  systemPowerCompanyType,
  propertyCompanyTenantMoveInYear,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  loanCategory,
} = loanFormModel.formField;

export class PropertyCompanyTenantMoveInYearValidation
  implements ValidationSchema
{
  public name = propertyCompanyTenantMoveInYear.name;
  public label = propertyCompanyTenantMoveInYear.label;
  public dependsOn: string[] = [
    systemPowerCompanyType.name,
    loanCategory.name,
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public positive = true;
  public min = {
    message: 'Year must be greater than 1900',
    value: 1900,
  };
  public max = {
    message: 'Year must be less than or equal to current year',
    value: new Date().getFullYear(),
  };
  public nullable = true;

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    loanCategory: Application['loan_category'],
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    schema
  ) {
    const required =
      isPropertyCompany(systemPowerCompanyType) &&
      isCoreOrExpressPlusLoan(loanCategory) &&
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        'Enter information for a single tenant';

    if (required) return schema.required('This document is required');
    else return schema.notRequired();
  }
}
export default new Validation(new PropertyCompanyTenantMoveInYearValidation());
