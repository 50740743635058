import { Application } from '../../../../../../../../types/api';
import { SYSTEM_POWER_USAGE_VALUES } from '../../../../../constants';
import {
  AdditionalDocumentsValidation,
  MeetingMinutesValidation,
  HoaBylawsValidation,
  LeaseOfTheSystemPropertyValidation,
  OfftakerPPAorLeaseAgreementValidation,
  ProofOfOfftakerCreditworthinessValidation,
  OffTakerLeaseOrProofOfOwnershipValidation,
  OffTakerUtilityBillValidation,
  PpaLeaseFinancialProjectionsValidation,
  ApprovalForCommunitySolarDeliveryValidation,
  CommunitySolarFinancialProjectionsValidation,
  SubscriberManagementCompanyContractValidation,
  PrincipalsValidation,
} from '../../../../../validations/required_documents';

import {
  CurrentOccupantLedgerValidation,
  MemberAssessmentPolicyValidation,
  PriorYearBudgetsValidation,
  NonProfitBoardResolutionValidation,
  ReligiousOrganizationAttendanceValidation,
  NonProfitBylawsCopyValidation,
  PropertyCompanyRentRollValidation,
  PropertyCompanyUploadRentRollOrProvideTenantInfoValidation,
  PropertyCompanyTenantNameValidation,
  PropertyCompanyTenantMoveInYearValidation,
  PropertyCompanyTenantRenewalYearValidation,
  PropertyCompanyTenantRenewalLengthValidation,
  PropertyCompanyTenantMonthlyRentPaymentValidation,
  PropertyCompanyTenantPaymentsSatisfactoryValidation,
  PgMortgageStatementValidation,
} from './validations/required_documents';

import {
  BankStatementsLastMonthValidation,
  BankStatements2MonthValidation,
  BankStatements3MonthValidation,
} from './validations/bank_statements';

export const powerSoldToSingleUserCondition = (
  powerUsage: Application['solar_system_power_usage'],
  singleOrMultipleUsers: Application['system_power_single_or_multiple_users']
) => {
  return (
    powerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
    singleOrMultipleUsers === 'Single User'
  );
};

export const powerIsCommunitySolarCondition = (
  powerUsage: Application['solar_system_power_usage'],
  singleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
  communityOrPPALease: Application['community_solar_or_ppa_lease']
) => {
  return (
    powerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
    singleOrMultipleUsers === 'Multiple Users' &&
    communityOrPPALease === 'Community Solar Arrangement'
  );
};

const schemaBuilder = (acc, validation) => {
  acc[validation.name] = validation.buildSchema();
  return acc;
};

export const bankStatementsValidation = [
  BankStatementsLastMonthValidation,
  BankStatements2MonthValidation,
  BankStatements3MonthValidation,
].reduce(schemaBuilder, {});

export const companyDocumentsValidation = [
  NonProfitBoardResolutionValidation,
  ReligiousOrganizationAttendanceValidation,
  NonProfitBylawsCopyValidation,
  PropertyCompanyUploadRentRollOrProvideTenantInfoValidation,
  PropertyCompanyRentRollValidation,
  PropertyCompanyTenantNameValidation,
  PropertyCompanyTenantMoveInYearValidation,
  PropertyCompanyTenantMonthlyRentPaymentValidation,
  PropertyCompanyTenantRenewalYearValidation,
  PropertyCompanyTenantRenewalLengthValidation,
  PropertyCompanyTenantPaymentsSatisfactoryValidation,
  PgMortgageStatementValidation,
].reduce(schemaBuilder, {});

export const hoaDocumentsValidation = [
  MemberAssessmentPolicyValidation,
  PriorYearBudgetsValidation,
  CurrentOccupantLedgerValidation,
  MeetingMinutesValidation,
  HoaBylawsValidation,
].reduce(schemaBuilder, {});

export const solarSystemDocumentsValidation = [
  LeaseOfTheSystemPropertyValidation,
  // Single User
  OfftakerPPAorLeaseAgreementValidation,
  ProofOfOfftakerCreditworthinessValidation,
  OffTakerLeaseOrProofOfOwnershipValidation,
  OffTakerUtilityBillValidation,
  PpaLeaseFinancialProjectionsValidation,
  // Community Solar
  ApprovalForCommunitySolarDeliveryValidation,
  CommunitySolarFinancialProjectionsValidation,
  SubscriberManagementCompanyContractValidation,
  PrincipalsValidation,
].reduce(schemaBuilder, {});

export const additionalDocumentationValidation = [
  AdditionalDocumentsValidation,
].reduce(schemaBuilder, {});
