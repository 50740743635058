import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { borrowerGrossRevenueLatest } = loanFormModel.formField;

enum Operator {
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL_TO = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL_TO = '>=',
  EQUAL_TO = '=',
}

class IsAnnualGrossRevenue implements Rule {
  private amount = 0;
  private operator: Operator;

  public setAmount(amount: number) {
    this.amount = amount;
    return this;
  }

  public isGreaterThan() {
    this.operator = Operator.GREATER_THAN;
    return this;
  }

  public isLessThan() {
    this.operator = Operator.LESS_THAN;
    return this;
  }

  public isGreaterThanOrEqualTo() {
    this.operator = Operator.GREATER_THAN_OR_EQUAL_TO;
    return this;
  }

  public isLessThanOrEqualTo() {
    this.operator = Operator.LESS_THAN_OR_EQUAL_TO;
    return this;
  }

  public isEqualTo() {
    this.operator = Operator.EQUAL_TO;
    return this;
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const annualGrossRevenue = application[borrowerGrossRevenueLatest.name];

    if (!annualGrossRevenue) return false;

    switch (this.operator) {
      case Operator.GREATER_THAN:
        return annualGrossRevenue > this.amount;
      case Operator.LESS_THAN:
        return annualGrossRevenue < this.amount;
      case Operator.GREATER_THAN_OR_EQUAL_TO:
        return annualGrossRevenue >= this.amount;
      case Operator.LESS_THAN_OR_EQUAL_TO:
        return annualGrossRevenue <= this.amount;
      case Operator.EQUAL_TO:
        return annualGrossRevenue === this.amount;
      default:
        return false;
    }
  }
}

export default new IsAnnualGrossRevenue();
