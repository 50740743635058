import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultApplicantName } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import { isPropertyCompany, isCoreOrExpressPlusLoan } from './helpers';

const {
  loanCategory,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  propertyCompanyTenantName,
  systemPowerCompanyType,
} = loanFormModel.formField;

export class PropertyCompanyTenantNameValidation implements ValidationSchema {
  public name = propertyCompanyTenantName.name;
  public label =
    propertyCompanyUploadRentRollOrProvideTenantInfo.getLabel(
      defaultApplicantName
    );
  public dependsOn: string[] = [
    systemPowerCompanyType.name,
    loanCategory.name,
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    loanCategory: Application['loan_category'],
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    schema
  ) {
    const required =
      isPropertyCompany(systemPowerCompanyType) &&
      isCoreOrExpressPlusLoan(loanCategory) &&
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        'Enter information for a single tenant';

    if (required) return schema.required('This field is required');
    return schema.notRequired();
  }
}
export default new Validation(new PropertyCompanyTenantNameValidation());
