import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { TR2YearsAgoRuleset } from '../../business/ruleset';
import { taxDocuments } from '../../form_models/loanFormModel';

const { taxReturns2Year, applicantFiledTaxReturnsInThePast } = taxDocuments;

export class TaxReturns2YearValidation implements ValidationSchema {
  public name = taxReturns2Year.name;
  public label = taxReturns2Year.getLabel(defaultApplicantName);
  public dependsOn: string[] = [applicantFiledTaxReturnsInThePast.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = TR2YearsAgoRuleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();

    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(taxReturns2Year.name, required);
  }
}
export default new Validation(new TaxReturns2YearValidation());
