import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    borrowerYearsManagementInPlace,
    borrowerManagementExperienceYears,
  },
} = loanFormModel;

export class BorrowerManagementExperienceYearsValidation
  implements ValidationSchema
{
  public name: string = borrowerManagementExperienceYears.name;
  public label: string = borrowerManagementExperienceYears.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [borrowerYearsManagementInPlace.name];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when = (borrowerYearsManagementInPlace, schema) => {
    if (borrowerYearsManagementInPlace < 15)
      return schema.required('This field is required');
    return schema;
  };
}
export default new Validation(
  new BorrowerManagementExperienceYearsValidation()
);
