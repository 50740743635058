import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalPhoneNumber },
} = loanFormModel;

export class PrincipalPhoneNumberValidation implements ValidationSchema {
  public name = principalPhoneNumber.name;
  public label = principalPhoneNumber.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: principalPhoneNumber.requiredErrorMsg,
    value: principalPhoneNumber.required,
  };
  public nullable = true;
  public min = {
    message: 'Must be 10 digits',
    value: 10,
  };
  public max = {
    message: 'Must be 10 digits',
    value: 10,
  };
  public matches = {
    message: 'Must be only digits',
    value: /^[0-9]+$/,
  };
  public typeError = {
    message: 'Must be a number',
    value: true,
  };
}

export default new Validation(new PrincipalPhoneNumberValidation());
