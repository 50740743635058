import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { TRLastYearRuleset } from '../../business/ruleset';
import { taxDocuments } from '../../form_models/loanFormModel';

const {
  taxReturnsLastYear,
  applicantFiledTaxReturnsInThePast,
  applicantFiledLastYearTaxReturns,
} = taxDocuments;

export class TaxReturnsLastYearValidation implements ValidationSchema {
  public name = taxReturnsLastYear.name;
  public label = taxReturnsLastYear.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledLastYearTaxReturns.name,
    applicantFiledTaxReturnsInThePast.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public ruleset = TRLastYearRuleset;

  public when(...args: any[]) {
    const result = args.pop();
    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(taxReturnsLastYear.name, required);
  }
}
export default new Validation(new TaxReturnsLastYearValidation());
