import CheckIcon from '@mui/icons-material/Check';
import FolderIcon from '@mui/icons-material/Folder';

export const FolderCheckMark = () => {
  return (
    <>
      <FolderIcon />
      <CheckIcon
        style={{
          color: 'white',
          position: 'absolute',
          fontSize: '.8rem',
        }}
      />
    </>
  );
};
