import {
  Application,
  LoanCategory,
} from '../../../../../../../../../../types/api';
import loanFormModelOld from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../form_models/loanFormModel';

const { loanCategory } = loanFormModelOld.formField;

const {
  propertyCompanyTenantMonthlyRentPayment,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
} = loanFormModel.formField;

export class PropertyCompanyTenantMonthlyRentPaymentValidation
  implements ValidationSchema
{
  public name = propertyCompanyTenantMonthlyRentPayment.name;
  public label = propertyCompanyTenantMonthlyRentPayment.label;
  public dependsOn: string[] = [
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    loanCategory.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public positive = true;
  public nullable = true;

  public when(
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    loanCategory: Application['loan_category'],
    schema
  ) {
    const required =
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO.ENTER_INFORMATION_FOR_SINGLE_TENANT &&
      loanCategory != LoanCategory.EXPRESS_PROPCO;

    if (required) return schema.required('This document is required');
    else return schema.notRequired();
  }
}
export default new Validation(
  new PropertyCompanyTenantMonthlyRentPaymentValidation()
);
