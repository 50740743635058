import { LoanCategory } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { principalIsPG },
} = loanFormModel;

export class PrincipalIsPGValidation implements ValidationSchema {
  public name = principalIsPG.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public typeError = {
    message: 'Choice is required',
    value: true,
  };

  public when(schema) {
    return schema.test({
      name: 'principalIsPGTest',
      test: function (value) {
        const loanCategory = this.from[1].value['loan_category'];

        // Changing isPGValue to LoanCategory.EXPRESS fixed a bug which made the field not required if
        // the required 51% was already achieved
        if (
          [
            LoanCategory.EXPRESS_OPCO,
            LoanCategory.EXPRESS_PROPCO,
            LoanCategory.EXPRESS_PLUS_OPCO,
            LoanCategory.EXPRESS_PLUS_PROPCO,
            LoanCategory.INDUSTRY_AGRICULTURE_OPCO,
            LoanCategory.INDUSTRY_AGRICULTURE_PROPCO,
          ].includes(loanCategory as LoanCategory)
        ) {
          return !!value || this.createError({ message: 'Choice is required' });
        }
        return true;
      },
    });
  }
}

export default new Validation(new PrincipalIsPGValidation());
