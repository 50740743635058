import {
  Product,
  LoanCategory,
  RelatedParty,
  PlaidAssetReportStatus,
} from '../../../../types/api';
import loanFormModel from '../components/loan_application/form_models/loanFormModel';
import loanFormModelNewFlow from '../pages/dashboard/loan_detail/flows/NewWelcomeFlow/form_models/loanFormModel';
import {
  MULTIPLE_USER_POWER_AGREEMENT,
  SYSTEM_POWER_USAGE_VALUES,
  BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT,
  NON_PROFIT_ORGANIZATION_TYPES_CHOICES,
  DEFAULT_PROPERTY_OWNER_NAME,
  FOR_PROFIT_COMPANY_TYPES,
} from '../constants';
import { ApplicationFormValues } from '../pages/dashboard/loan_detail/LoanDetailTypes';

const {
  borrowerBusinessEstablishedYear,
  systemPropertyCurrentlyPayingMortgage,
  nonProfitRecievedBoardApproval,
  borrowerIsReligiousOrganization,
  principalAverageCashBalance,
  systemPowerSingleOrMultipleUsers,
  systemPowerCommunityOrPPALease,
  systemPowerUsage,
  systemPropertyOwnershipStatus,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  borrowerIsHighRisk,
  mailingAddressSameAsBusinessPicklist,
  borrowerYearsManagementInPlace,
  systemPowerTaxEquityPartnering,
  systemPropertyAddressIsSameAsBusiness,
  systemPropertyLeaseExpirationYear,
  loanCategory,
  borrowerHasCreditUnderOtherName,
  forProfitCompanyType,
  businessTypeForProfitOrNonProfit,
  industryBorrowerReported,
  nonProfitOrganizationType,
  documents: { plaidAssetReport },
  borrowerBusinessStructure,
  systemPropertyLegalNameOfOwner,
  systemPropertyOwnerAffiliatedWithBorrower,
  borrowerGrossRevenueLatest,
} = loanFormModel.formField;

const { businessAddressIsSameAsSystemProperty } =
  loanFormModelNewFlow.formField;
export class BusinessRuleBase {
  protected data: Partial<ApplicationFormValues>;

  constructor(data: Partial<ApplicationFormValues>) {
    this.data = data;
  }

  public isPersonalGuarantor(): boolean {
    return this.data['is_personal_guarantor'] === 'Yes';
  }

  public isBorrowerReligiousOrg(): boolean {
    return this.data[borrowerIsReligiousOrganization.name] === 'Yes';
  }

  public isNonProfit(): boolean {
    // HOA is below, but it had different rules, so kept out of this list to keep the logic
    return [
      LoanCategory.NPO_FBO,
      LoanCategory.NPO_EDUCATIONAL_INSTITUTE,
      LoanCategory.NPO_OTHER,
    ].includes(this.data[loanCategory.name] as LoanCategory);
  }

  public hasBoardApproval(): boolean {
    return this.data[nonProfitRecievedBoardApproval.name] === 'Yes';
  }

  public isBorrowerStillPayingMortgage(): boolean {
    return this.data[systemPropertyCurrentlyPayingMortgage.name] === 'Yes';
  }

  public getBorrowerBusinessEstablishedYear(): number | undefined | null {
    return this.data[borrowerBusinessEstablishedYear.name];
  }

  public isBusinessEstablishedLessThan(years: number): boolean {
    const yearBusinessEstablished =
      this.data[borrowerBusinessEstablishedYear.name];
    return Boolean(
      yearBusinessEstablished &&
        new Date().getFullYear() - yearBusinessEstablished < years
    );
  }

  public isBorrowerManagementInPlaceLessThan(years: number): boolean {
    const managementYears = this.data[borrowerYearsManagementInPlace.name];
    return (managementYears && managementYears < years) || false;
  }

  public isPropertyCompany(): boolean {
    return [
      LoanCategory.EXPRESS_PROPCO,
      LoanCategory.EXPRESS_PLUS_PROPCO,
      LoanCategory.CORE_PROPCO,
    ].includes(this.data[loanCategory.name] as LoanCategory);
  }

  public isHOA(): boolean {
    return this.data[loanCategory.name] === LoanCategory.NPO_HOA;
  }

  public isCompanyAgriculture(): boolean {
    return [
      LoanCategory.INDUSTRY_AGRICULTURE_OPCO,
      LoanCategory.INDUSTRY_AGRICULTURE_PROPCO,
    ].includes(this.data[loanCategory.name] as LoanCategory);
  }

  public propertyCompanyHasSingleTenantInfo(): boolean {
    return (
      this.data[propertyCompanyUploadRentRollOrProvideTenantInfo.name] ===
      'Enter information for a single tenant'
    );
  }

  public propertyCompanyHasRentRoll(): boolean {
    return (
      this.data[propertyCompanyUploadRentRollOrProvideTenantInfo.name] ===
      'Upload rent roll'
    );
  }

  public isLeased(): boolean {
    return this.data[systemPropertyOwnershipStatus.name] === 'LEASED';
  }

  public isSingleUser(): boolean {
    return this.data[systemPowerSingleOrMultipleUsers.name] === 'Single User';
  }

  public isMultipleUser(): boolean {
    return (
      this.data[systemPowerSingleOrMultipleUsers.name] === 'Multiple Users'
    );
  }

  public isPowerPPALease(): boolean {
    return (
      this.data[systemPowerCommunityOrPPALease.name] ===
      MULTIPLE_USER_POWER_AGREEMENT.PPA_LEASE
    );
  }

  public isPowerCommunitySolar(): boolean {
    return (
      this.data[systemPowerCommunityOrPPALease.name] ===
      'Community Solar Arrangement'
    );
  }

  public isPPAOrLeaseAgreement(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
        SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      this.isMultipleUser() &&
      this.data[systemPowerCommunityOrPPALease.name] ===
        MULTIPLE_USER_POWER_AGREEMENT.PPA_LEASE
    );
  }

  public isCommunitySolar(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
        SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
      this.isMultipleUser() &&
      this.data[systemPowerCommunityOrPPALease.name] ===
        'Community Solar Arrangement'
    );
  }

  public isSoldToSingleUser(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
        SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY && this.isSingleUser()
    );
  }

  public findApplicantSameAsPrincipal(): RelatedParty | undefined {
    return this.data.principals?.find(
      (principal) => principal['applicant_same_as_principal'] === 'Yes'
    );
  }

  public isSoldToAnotherEntity(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
      SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY
    );
  }

  public isOffset(): boolean {
    return (
      this.data[systemPowerUsage.name] ===
      SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL
    );
  }

  public isFundedByTaxEquity(): boolean {
    return this.data[systemPowerTaxEquityPartnering.name] === 'Yes';
  }

  public getBorrowerBusinessName(): string {
    return this.data['borrower_business_name'] ?? 'the Business';
  }

  public isBorrowerHighRisk(): boolean {
    return this.data[borrowerIsHighRisk.name] === 'Yes';
  }

  public isMailingAddressIsNotSameAsBusiness(): boolean {
    return this.data[mailingAddressSameAsBusinessPicklist.name] === 'No';
  }

  public isMailingAddressSameAsSystemProperty(): boolean {
    return this.data[mailingAddressSameAsBusinessPicklist.name] === 'Yes';
  }

  public getBusinessAddress(): string {
    const busAddress = this.data['business_address'];
    const busAddressCity = this.data['business_address_city'];
    const busAddressState = this.data['business_address_state'];
    const busAddressZipCode = this.data['business_address_zip_code'];
    if (
      !busAddress ||
      !busAddressCity ||
      !busAddressState ||
      !busAddressZipCode
    )
      return '';

    return `${busAddress}, ${busAddressCity}, ${busAddressState} ${busAddressZipCode}`;
  }

  public getSystemPropertyAddress(): string {
    const sysAddress = this.data['sys_prop_address'];
    const sysAddressCity = this.data['sys_prop_address_city'];
    const sysAddressState = this.data['sys_prop_address_state'];
    const sysAddressZipCode = this.data['sys_prop_address_zip_code'];
    if (
      !sysAddress ||
      !sysAddressCity ||
      !sysAddressState ||
      !sysAddressZipCode
    )
      return '';
    return `${sysAddress}, ${sysAddressCity}, ${sysAddressState} ${sysAddressZipCode}`;
  }

  public isSystemPropertyAddressSameAsBusiness(): boolean {
    const same = this.data[systemPropertyAddressIsSameAsBusiness.name];
    return same === 'Yes' || same == null;
  }

  // New flow only
  public isBusinessAddressSameAsSystemProperty(): boolean {
    return this.data[businessAddressIsSameAsSystemProperty.name] === 'Yes';
  }

  public getSystemPropertyExpirationYear(): number {
    return this.data[systemPropertyLeaseExpirationYear.name] ?? 0;
  }

  // Get the warning message related to system_property_lease_expiration_year
  // showed when lease term is less than 80% of loan term
  public getLease80percentLoanTermWarning(): string {
    return systemPropertyLeaseExpirationYear.lease80percentLoanTermWarning;
  }

  public getLoanProduct(): Product | undefined {
    return this.data['product'];
  }

  public isExpressPlusLoan(): boolean {
    const category = this.data[loanCategory.name] as LoanCategory | null;
    return (
      category !== null &&
      category !== undefined &&
      [
        LoanCategory.EXPRESS_PLUS_OPCO,
        LoanCategory.EXPRESS_PLUS_PROPCO,
      ].includes(category)
    );
  }

  public isExpressLoan(): boolean {
    const category = this.data[loanCategory.name] as LoanCategory | null;
    return (
      category !== null &&
      category !== undefined &&
      [LoanCategory.EXPRESS_OPCO, LoanCategory.EXPRESS_PROPCO].includes(
        category
      )
    );
  }

  public isCoreLoan(): boolean {
    const category = this.data[loanCategory.name] as LoanCategory | null;
    return (
      category !== null &&
      category !== undefined &&
      [LoanCategory.CORE_OPCO, LoanCategory.CORE_PROPCO].includes(category)
    );
  }

  public isCoreOrExpressPlusLoan(): boolean {
    return this.isExpressPlusLoan() || this.isCoreLoan();
  }

  public hasBorrowerCreditUnderAnotherName(): boolean {
    return this.data[borrowerHasCreditUnderOtherName.name] === 'Yes';
  }

  public getAverageCashBalance(): number {
    return this.data['principals']?.[0][principalAverageCashBalance.name];
  }

  public isAssetReportSuccessful(): boolean {
    return (
      this.data[plaidAssetReport.name]?.asset_report_status ===
      PlaidAssetReportStatus.SUCCESS
    );
  }

  public isBusinessForProfit(): boolean {
    return (
      this.data['business_type_for_profit_or_non_profit'] ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.FOR_PROFIT
    );
  }

  public isBusinessNonProfit(): boolean {
    return (
      this.data['business_type_for_profit_or_non_profit'] ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.NON_PROFIT
    );
  }

  public isSpecialPayment(): boolean {
    return this.data['loan_version']?.special_payment_amount !== undefined;
  }

  public getSpecialPaymentAmount(): number {
    return this.data['loan_version']?.special_payment_amount ?? 0;
  }

  public getPropertyLegalNameOfOwner(): string {
    const legalName = this.data[systemPropertyLegalNameOfOwner.name];
    return legalName ? legalName : DEFAULT_PROPERTY_OWNER_NAME;
  }

  public getForProfitCompanyType(): string {
    return this.data[forProfitCompanyType.name] || '';
  }

  public getBusinessTypeForProfitOrNonProfit(): string {
    return this.data[businessTypeForProfitOrNonProfit.name] ?? '';
  }

  public isBusinessTypeForProfit(): boolean {
    return (
      this.getBusinessTypeForProfitOrNonProfit() ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.FOR_PROFIT
    );
  }

  public isBusinessTypeNonProfit(): boolean {
    return (
      this.getBusinessTypeForProfitOrNonProfit() ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.NON_PROFIT
    );
  }

  public getIndustryBorrowerReported(): string {
    return this.data[industryBorrowerReported.name] ?? '';
  }

  public isFaithBasedOrg(): boolean {
    return (
      this.data[nonProfitOrganizationType.name] ===
      NON_PROFIT_ORGANIZATION_TYPES_CHOICES[0].value
    );
  }

  public isHomeownerAssociation(): boolean {
    return (
      this.data[nonProfitOrganizationType.name] ===
      NON_PROFIT_ORGANIZATION_TYPES_CHOICES[1].value
    );
  }

  public getBorrowerBusinessStructure(): string {
    return this.data[borrowerBusinessStructure.name] ?? '';
  }

  public getAddress(): string {
    if (this.isSystemPropertyAddressSameAsBusiness()) {
      return this.getBusinessAddress();
    }
    return this.getSystemPropertyAddress();
  }

  public isPropertyOwnerRelatedToBorrowerBusiness(): boolean {
    return this.data[systemPropertyOwnerAffiliatedWithBorrower.name] === 'Yes';
  }

  public isAnnualGrossRevenueLessThan(amount: number): boolean {
    const annualGrossRevenue = this.data[borrowerGrossRevenueLatest.name];
    return Boolean(annualGrossRevenue && annualGrossRevenue < amount);
  }

  get isExpressPropCo(): boolean {
    return this.data[loanCategory.name] === LoanCategory.EXPRESS_PROPCO;
  }

  get isCollectRent(): boolean {
    return (
      this.data[forProfitCompanyType.name] ===
      FOR_PROFIT_COMPANY_TYPES.COLLECTS_RENT_FROM_TENANTS
    );
  }
}
