import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  RulesetResult,
  IsBusinessEstablishedMoreThan,
  isExpressLoan,
  HasApplicantFiledTRLastYear,
  IsBetweenJanAndMidFeb,
  IsBetweenMidAprilMidMay,
  IsBetweenMidMayMidAug,
  IsBetweenMidAugMidOct,
} from '../rules';
import IsBetweenMidFebMidApril from '../rules/IsBetweenMidFebMidApril';

class TR3YearsAgoRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const isBetweenJanAndMidApr = [
      IsBetweenJanAndMidFeb,
      IsBetweenMidFebMidApril,
    ].some((Is) => Is.evaluate(data));

    const isBetweenMidAprilAndMidOct = [
      IsBetweenMidAprilMidMay,
      IsBetweenMidMayMidAug,
      IsBetweenMidAugMidOct,
    ].some((Is) => Is.evaluate(data));

    const hasApplicantFiledTRLastYear =
      HasApplicantFiledTRLastYear.evaluate(data);
    const isExpress = isExpressLoan.evaluate(data);
    const isEstablishedMoreThan3YearsAgo =
      IsBusinessEstablishedMoreThan.setYears(3).evaluate(data);

    if (HasApplicantFiledTR.evaluate(data)) {
      if (!isExpress)
        return isEstablishedMoreThan3YearsAgo
          ? RulesetResult.REQUIRED
          : RulesetResult.OPTIONAL;
      // After here we have express loans
      if (isBetweenJanAndMidApr)
        return isEstablishedMoreThan3YearsAgo
          ? RulesetResult.REQUIRED
          : RulesetResult.OPTIONAL;
      if (isBetweenMidAprilAndMidOct) {
        if (hasApplicantFiledTRLastYear) return RulesetResult.HIDDEN;
        if (isEstablishedMoreThan3YearsAgo && !hasApplicantFiledTRLastYear)
          return RulesetResult.REQUIRED;
        return RulesetResult.OPTIONAL;
      }
      return RulesetResult.HIDDEN;
    }
    return RulesetResult.HIDDEN;
  };
}

export default new TR3YearsAgoRuleset();
