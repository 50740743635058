import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import FinancialStatementLastYearQ3Ruleset from '../../business/ruleset/FinancialStatementLastYearQ3Ruleset';
import { taxDocuments } from '../../form_models/loanFormModel';

const { financialStatementLastYearQ3 } = taxDocuments;

export class FinancialStatementLastYearQ3Validation
  implements ValidationSchema
{
  public name = financialStatementLastYearQ3.name;
  public label = financialStatementLastYearQ3.getLabel(defaultApplicantName);
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = FinancialStatementLastYearQ3Ruleset;

  public when(schema, result) {
    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(
      financialStatementLastYearQ3.name,
      required
    );
  }
}
export default new Validation(new FinancialStatementLastYearQ3Validation());
