import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  IsBetweenMidAprilMidMay,
  IsBetweenMidAugMidOct,
  IsBetweenMidMayMidAug,
  RulesetResult,
  HasApplicantFiledTRLastYear,
  isExpressLoan,
  IsBetweenJanAndMidFeb,
} from '../rules';
import IsBetweenMidFebMidApril from '../rules/IsBetweenMidFebMidApril';
import IsBusinessEstablishedEqualTo from '../rules/IsBusinessEstablishedEqualTo';
import IsBusinessEstablishedLessThan from '../rules/IsBusinessEstablishedLessThan';

class FinancialStatementFullYearRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const isBetweenMidAprilAndMidOct = [
      IsBetweenMidAprilMidMay,
      IsBetweenMidMayMidAug,
      IsBetweenMidAugMidOct,
    ].some((Is) => Is.evaluate(data));

    if (HasApplicantFiledTR.evaluate(data)) {
      const isExpress = isExpressLoan.evaluate(data);

      if (
        (isBetweenMidAprilAndMidOct &&
          !HasApplicantFiledTRLastYear.evaluate(data)) ||
        IsBetweenMidFebMidApril.evaluate(data)
      )
        return isExpress ? RulesetResult.OPTIONAL : RulesetResult.REQUIRED;
    } else {
      const businessEstablishedTwoYearsAgo =
        IsBusinessEstablishedEqualTo.setYears(2).evaluate(data);
      const businessEstablishedLessThanTwoYearsAgo =
        IsBusinessEstablishedLessThan.setYears(2).evaluate(data);
      if (
        (isBetweenMidAprilAndMidOct ||
          IsBetweenMidFebMidApril.evaluate(data)) &&
        businessEstablishedTwoYearsAgo
      )
        return RulesetResult.REQUIRED;
      else if (
        !IsBetweenJanAndMidFeb.evaluate(data) &&
        businessEstablishedLessThanTwoYearsAgo
      )
        return RulesetResult.OPTIONAL;
    }
    return RulesetResult.HIDDEN;
  };
}

export default new FinancialStatementFullYearRuleset();
