import { YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { mailingAddressCity, mailingAddressSameAsBusinessPicklist },
} = loanFormModel;

export class MailingAddressCityValidation implements ValidationSchema {
  public name: string = mailingAddressCity.name;
  public label: string = mailingAddressCity.label;
  public dependsOn: string[] = [mailingAddressSameAsBusinessPicklist.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    mailingAddressSameAsBusinessPicklist: YesNo,
    schema: Yup.StringSchema
  ) {
    return mailingAddressSameAsBusinessPicklist === 'Yes'
      ? schema
      : schema.required('City is a required field');
  }
}
export default new Validation(new MailingAddressCityValidation());
