import { COMPANY_TYPES } from '../../constants';
import { LoanCategory } from '../../../../../types/api';

export const isPropertyCompany = (companyType: string | undefined | null) => {
  return Boolean(companyType === COMPANY_TYPES.PROPERTY_COMPANY);
};

export const isCoreOrExpressPlusLoan = (
  loanCategory: LoanCategory | undefined | null
) => {
  return Boolean(
    loanCategory &&
      [
        LoanCategory.CORE_OPCO,
        LoanCategory.CORE_PROPCO,
        LoanCategory.EXPRESS_PLUS_OPCO,
        LoanCategory.EXPRESS_PLUS_PROPCO,
      ].includes(loanCategory)
  );
};
