import {
  RELATED_PARTY_TYPES,
  YEARS_BUSINESS_ESTABLISHED_FLOOR,
} from '../constants';
import loanFormModel from '../pages/dashboard/loan_detail/flows/NewWelcomeFlow/form_models/loanFormModel';
import { ApplicationFormValues } from '../pages/dashboard/loan_detail/LoanDetailTypes';
import { BusinessRuleBase } from './BusinessBase';

const {
  companyDocuments: { pgMortgageStatement },
} = loanFormModel.formField;

// Required Documents Business Logic
export class RequiredDocumentsBL extends BusinessRuleBase {
  constructor(data: ApplicationFormValues) {
    super(data);
  }

  public isMortgageStatementRequired(): boolean {
    return (
      this.isPersonalGuarantor() &&
      this.isBorrowerStillPayingMortgage() &&
      !this.isLeased()
    );
  }

  public isMortgageStatementRequiredNewFLow(): boolean {
    return (
      (this.isExpressLoan() ||
        this.isExpressPlusLoan() ||
        this.isCoreLoan() ||
        this.isCompanyAgriculture()) &&
      this.isBorrowerStillPayingMortgage()
    );
  }

  public canShowRentRollAndTenantInfo(): boolean {
    return this.isPropertyCompany() && this.isCoreOrExpressPlusLoan();
  }

  public canShowCompanyDocuments(): boolean {
    const isPersonalGuarantor = this.isPersonalGuarantor();
    const mortgageStatementRequired = this.isMortgageStatementRequired();

    return (
      this.isBusinessEstablishedLessThan(YEARS_BUSINESS_ESTABLISHED_FLOOR) ||
      this.isNonProfit() ||
      this.canShowRentRollAndTenantInfo() ||
      mortgageStatementRequired
    );
  }

  public canShowPrincipalDocuments(): boolean {
    if (!this.data.principals) return false;
    return this.data.principals.some(
      (principal) =>
        principal.principal_type === RELATED_PARTY_TYPES.ENTITY ||
        principal.principal_is_personal_guarantor === 'Yes'
    );
  }

  public isTenantLeaseTermRequired(): boolean {
    return (
      !this.isPropertyCompany() && this.propertyCompanyHasSingleTenantInfo()
    );
  }

  public canShowSolarSystemDocuments(): boolean {
    return (
      this.isLeased() ||
      this.isSoldToSingleUser() ||
      this.isPPAOrLeaseAgreement() ||
      this.isCommunitySolar()
    );
  }

  public getRules() {
    const rules = {
      showRentRollAndTenantInfo: this.canShowRentRollAndTenantInfo(),
      showCompanyDocuments: this.canShowCompanyDocuments(),
      mortgageStatementRequired: this.isMortgageStatementRequired(),
      mortgageStatementRequiredNewFlow:
        this.isMortgageStatementRequiredNewFLow(),
      isSoldToSingleUser: this.isSoldToSingleUser(),
      isLeased: this.isLeased(),
      isPPAOrLeaseAgreement: this.isPPAOrLeaseAgreement(),
      isCommunitySolar: this.isCommunitySolar(),
      enterTenantInfo: this.propertyCompanyHasSingleTenantInfo(),
      uploadRentRoll: this.propertyCompanyHasRentRoll(),
      isNonProfit: this.isNonProfit(),
      isReligiousOrg: this.isBorrowerReligiousOrg(),
      isPropertyCompany: this.isPropertyCompany(),
      businessEstablishedLessThan3Years: this.isBusinessEstablishedLessThan(
        YEARS_BUSINESS_ESTABLISHED_FLOOR
      ),
      recievedBoardApproval: this.hasBoardApproval(),
      isHoa: this.isHOA(),
      showApplicantSameAsPrincipal: this.findApplicantSameAsPrincipal(),
      isPersonalGuarantor: this.isPersonalGuarantor(),
      showPrincipalDocuments: this.canShowPrincipalDocuments(),
      isTenantLeaseTermRequired: this.isTenantLeaseTermRequired(),
      showSolarSystemDocuments: this.canShowSolarSystemDocuments(),
      isPayingMortgage: this.isBorrowerStillPayingMortgage(),
    };

    return rules;
  }

  get isTentantInformationVisible(): boolean {
    return this.isCollectRent && !this.isExpressPropCo;
  }

  public getRequiredRuleFor(name: string) {
    const rules = {
      [pgMortgageStatement.name]:
        this.isMortgageStatementRequiredNewFLow() &&
        (this.isExpressLoan() || this.isExpressPlusLoan()),
    };
    if (rules[name] === undefined) throw new Error(`Rule ${name} not found`);
    return rules[name];
  }
}
