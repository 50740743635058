import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { borrowerBusinessEstablishedYear } = loanFormModel.formField;

class IsBusinessEstablishedEqualTo implements Rule {
  private years = 2;

  public setYears(years: number) {
    this.years = years;
    return this;
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const yearBusinessEstablished =
      application[borrowerBusinessEstablishedYear.name];
    return Boolean(
      yearBusinessEstablished &&
        new Date().getFullYear() - yearBusinessEstablished == this.years
    );
  }
}

export default new IsBusinessEstablishedEqualTo();
