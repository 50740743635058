import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  RulesetResult,
  IsBusinessEstablishedMoreThan,
} from '../rules';

class TR2YearsAgoRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);
    if (!hasApplicantEverFiledTR) return RulesetResult.HIDDEN;
    if (
      hasApplicantEverFiledTR &&
      IsBusinessEstablishedMoreThan.setYears(2).evaluate(data)
    ) {
      return RulesetResult.REQUIRED;
    }
    return RulesetResult.OPTIONAL;
  };
}

export default new TR2YearsAgoRuleset();
