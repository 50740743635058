import {
  genericRequiredFieldError,
  RELATED_PARTY_TYPES,
} from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalType, principalTrustTIN },
} = loanFormModel;

export class PrincipalTrustTINValidation implements ValidationSchema {
  public name = principalTrustTIN.name;
  public label = principalTrustTIN.label;
  public dependsOn: string[] = [principalType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, schema) {
    if (principalType === RELATED_PARTY_TYPES.ENTITY) {
      return schema.required(genericRequiredFieldError);
    }
    return schema.notRequired();
  }
}
export default new Validation(new PrincipalTrustTINValidation());
