import { YesNo } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { mailingAddressState, mailingAddressSameAsBusinessPicklist },
} = loanFormModel;

export class MailingAddressStateValidation implements ValidationSchema {
  public name: string = mailingAddressState.name;
  public label: string = mailingAddressState.label;
  public dependsOn: string[] = [mailingAddressSameAsBusinessPicklist.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    mailingAddressSameAsBusinessPicklist: YesNo,
    schema: Yup.StringSchema
  ) {
    return mailingAddressSameAsBusinessPicklist === 'Yes'
      ? schema
      : schema.required('State is a required field');
  }
}
export default new Validation(new MailingAddressStateValidation());
