import { RACE } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { principalRace } = loanFormModel.formField;

class IsBlackOrAfricanAmerican implements Rule {
  private principalId: any;

  public setPrincipalId(id: any) {
    this.principalId = id;
    return this;
  }

  private getPrincipal(data: Partial<ApplicationFormValues>) {
    return data.principals?.find(
      (principal) => principal.id === this.principalId
    );
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const principal = this.getPrincipal(application);

    const race = principal?.[principalRace.name] || [];

    return race.includes(RACE.BAA);
  }
}

export default new IsBlackOrAfricanAmerican();
