import { RELATED_PARTY_TYPES } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: {
    principalType,
    principalNonUSPersonIssuanceCountry,
    principalIsUSCitizen,
  },
} = loanFormModel;

export class PrincipalNonUSPersonIssuanceCountryValidation
  implements ValidationSchema
{
  public name = principalNonUSPersonIssuanceCountry.name;
  public label = principalNonUSPersonIssuanceCountry.label;
  public dependsOn: string[] = [principalType.name, principalIsUSCitizen.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, principalIsUSCitizen: string, schema) {
    if (
      principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
      principalIsUSCitizen === 'No'
    ) {
      return schema
        .required(principalNonUSPersonIssuanceCountry.requiredErrorMsg)
        .min(2);
    }
    return schema.transform(() => null);
  }
}
export default new Validation(
  new PrincipalNonUSPersonIssuanceCountryValidation()
);
