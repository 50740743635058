import { INTERVAL_DATES } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Rule } from '../../interfaces/Rule';

class IsBetweenJanAndMidFeb implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    return INTERVAL_DATES().JanMidFeb;
  }
}

export default new IsBetweenJanAndMidFeb();
