import { LoanCategory } from '../../../../../../../../../../types/api';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { loanCategory } = loanFormModel.formField;

class IsExpressLoan implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const category = application[loanCategory.name] as LoanCategory | null;
    return (
      category !== null &&
      category !== undefined &&
      [LoanCategory.EXPRESS_OPCO, LoanCategory.EXPRESS_PROPCO].includes(
        category
      )
    );
  }
}

export default new IsExpressLoan();
