import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { FinancialStatementFullYearRuleset } from '../../business/ruleset';
import loanFormModel, { taxDocuments } from '../../form_models/loanFormModel';

const { financialStatementFullYear, applicantFiledTaxReturnsInThePast } =
  taxDocuments;

const { borrowerBusinessEstablishedYear } = loanFormModel.formField;

export class FinancialStatementFullYearValidation implements ValidationSchema {
  public name = financialStatementFullYear.name;
  public label = financialStatementFullYear.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    borrowerBusinessEstablishedYear.name,
    applicantFiledTaxReturnsInThePast.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = FinancialStatementFullYearRuleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(financialStatementFullYear.name, required);
  }
}
export default new Validation(new FinancialStatementFullYearValidation());
