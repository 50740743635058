import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useNavigate,
  Link as RouterLink,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import {
  Box,
  Grid,
  DialogActions,
  CircularProgress,
  Button,
  Typography,
  SvgIcon,
} from '@mui/material';
import toast from 'react-hot-toast';

import { createBorrowerAccount } from '../../../../services/apis/InstallerAPIs';
import { applicationAPI } from '../../../../services/apis';
import useAuth from '../../../../hooks/useAuth';
import { useDisclosure } from '../../../../hooks/useDisclosure';
import {
  INSTALLER_DASHBOARD_PATH,
  maxFileSizeInBytes,
  DOCUMENT_TYPE,
  REQUIRED_DOCUMENT_REQUIRED_FOR_STEP,
} from '../../../../constants';
import InfoDialogueWithCtaButton from '../../../InfoDialogueWithCtaButton';
import DocumentSuccess from './../icons/DocumentSuccess.svg';
import animationData from './../icons/Loading.json';
import staticImage from './../icons/LoadingStatic.svg';
import { useQueryClient } from 'react-query';
import { INSTALLER_APPS_QUERY } from '../../../../queries';
import noticeError from '../../../../utils/errors/noticeError';
import { ArrowBack } from '@mui/icons-material';
import getValidationSchema, { InitialValues, urlParamsSchema } from './schema';
import {
  Control,
  UseFormSetError,
  useController,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  InstallerProduct,
  ProductFamily,
  SunstoneDocument,
} from '../../../../../../../types/api';
import LoanInformation from './fieldsets/LoanInformation';
import BusinessContactInformationFieldset from './fieldsets/BusinessContactInformation';
import SolarProjectInformation from './fieldsets/SolarProjectInformation';
import { Helmet } from 'react-helmet-async';
import NonSolarProjectComponents from './fieldsets/NonSolarProjectComponents';
import getFormData from '../../../../utils/getFormData';
import { getAtPath, traverse } from '../../../../utils/helpers';
import SunstoneDocumentFileDropzone from '../../../SunstoneDocumentFileDropzone';
import bytesToSize from '../../../../utils/bytesToSize';
import { Tracker } from '../../../../utils/tracker';
import { sendMonthlyPaymentRequest } from '../../../loan_application/utils/helpers';
import Lottie from '../../../lottie/Lottie';

export type SolarEconomics = {
  economics?: {
    loanAmount?: number | string;
    interestRate?: number;
    loanProductId?: string;
    installerProductId?: string;
    y1saving?: number;
    systemLocation?: string;
    y1production?: number;
    systemSize?: number;
    recInfo?: string;
    isSpecial?: string;
    specialPaymentPercentage?: number;
  };
};

const getInitialValues = (
  loanAmount: number | string,
  productId: string,
  installerProductId: string,
  installerProducts: InstallerProduct[],
  y1production?: number,
  y1saving?: number,
  systemLocation?: string,
  systemSize?: number,
  recInfo?: string,
  isSpecial?: string,
  specialPaymentPercentage?: number
): InitialValues & SolarEconomics => {
  const installerProduct = installerProducts.find(
    (p) => p.id === installerProductId
  );

  // loanTerm is term + amort with leading 0s.  e.g. '0505' or '1020'
  let loanTerm = '';
  if (installerProduct?.product) {
    loanTerm =
      (installerProduct?.product.loan_term_years + 100).toString().slice(1) +
      (installerProduct?.product.amortization_years + 100).toString().slice(1);
  }

  const loanType =
    installerProduct?.product.family === ProductFamily.PREMIUM
      ? ProductFamily.STANDARD
      : installerProduct?.product.family;

  return {
    borrower: { name: '' },
    loanTerm: loanTerm,
    loanType: loanType,
    contact: {
      first_name: '',
      last_name: '',
      position_title: '',
      email: '',
      phone: '',
    },
    economics: {
      loanAmount,
      interestRate: installerProduct?.product.apr_percentage,
      loanProductId: installerProduct?.product.id,
      installerProductId: installerProduct?.id,
      y1saving,
      y1production,
      systemLocation,
      systemSize,
      recInfo,
      isSpecial,
      specialPaymentPercentage,
    },
    application: {
      sys_prop_address_state: systemLocation ?? '',
      rec_info: recInfo ?? null,
      loan_product: installerProductId,
      loan_amount: loanAmount,
      project_timeline_estimation: '',
      has_non_solar_component: '',
      non_solar_amount: 0,
      energy_storage_amount: '',
      ev_charging_amount: '',
      roof_repair_amount: '',
      other_non_solar_amount: '',
      non_solar_components: '',
      deal_description_and_context: '',
      system_size_in_k_ws: systemSize ?? null,
      year_1_system_production_kwh: y1production ?? null,
      year_1_total_solar_savings: y1saving ?? null,
      total_system_cost: null,
      borrower_advance: '',
      special_payment_percentage: specialPaymentPercentage ?? null,
    },
    loan_proposal: {
      file: undefined as unknown as File,
    },
    utility_bills: [],
    incentive_documents: [],
    nonSolar: {
      hasNonSolarComponent: undefined,
      nonSolarAmount: 0,
      energyStorage: false,
      evCharging: false,
      roofRepair: false,
      other: false,
      otherValue: '',
    },
    mounting: {
      roof: false,
      ground: false,
      carport: false,
      other: false,
      otherValue: '',
    },
  };
};

const safeParseURLParams = (params: URLSearchParams) => {
  const parsedParams = urlParamsSchema.safeParse(
    Object.fromEntries(params.entries())
  );
  if (parsedParams.success) return parsedParams.data;
  return null;
};

const NewBorrowerForm = () => {
  const { user, account } = useAuth();
  const [params, _setSearchParams] = useSearchParams();
  const parsedParams = safeParseURLParams(params);
  const locationState = useLocation().state;
  const navigate = useNavigate();
  const tracker = Tracker.getInstance();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(
    () =>
      getInitialValues(
        parsedParams?.loanAmount ?? '',
        parsedParams?.productId ?? '',
        parsedParams?.installerProductId ?? '',
        account?.products ?? [],
        parsedParams?.y1production,
        parsedParams?.y1saving,
        parsedParams?.systemLocation,
        parsedParams?.systemSize,
        parsedParams?.recInfo,
        parsedParams?.isSpecial,
        parsedParams?.specialPaymentPercentage
      ),
    [
      parsedParams?.loanAmount,
      parsedParams?.productId,
      parsedParams?.installerProductId,
      account?.products,
      parsedParams?.y1production,
      parsedParams?.y1saving,
      parsedParams?.systemLocation,
      parsedParams?.systemSize,
      parsedParams?.recInfo,
      parsedParams?.isSpecial,
      parsedParams?.specialPaymentPercentage,
    ]
  );

  const queryClient = useQueryClient();
  const successModal = useDisclosure();
  const loadingModal = useDisclosure();
  const [successModalData, setSuccessModalData] = useState<{
    businessName: string | null;
    email: string | null;
  }>({
    businessName: null,
    email: null,
  });

  const validationSchema = useMemo(
    () => getValidationSchema(user?.email ?? ''),
    [user?.email]
  );

  const {
    control,
    formState,
    setValue,
    watch,
    trigger,
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });
  const { isSubmitting, isValid } = formState;

  const [
    mountingRoof,
    mountingCarport,
    mountingGround,
    mountingOther,
    nonSolarHasNonSolarComponent,
    nonSolarRoofRepair,
    nonSolarOther,
    nonSolarEnergyStorage,
    nonSolarEvCharging,
    loanAmount,
    installerProductId,
    specialPaymentPercentage,
  ] = watch([
    'mounting.roof',
    'mounting.carport',
    'mounting.ground',
    'mounting.other',
    'nonSolar.hasNonSolarComponent',
    'nonSolar.roofRepair',
    'nonSolar.other',
    'nonSolar.energyStorage',
    'nonSolar.evCharging',
    'application.loan_amount',
    'application.loan_product',
    'application.special_payment_percentage',
  ]);

  useEffect(() => {
    trigger('mountingChecked');
  }, [trigger, mountingRoof, mountingCarport, mountingGround, mountingOther]);

  useEffect(() => {
    trigger('nonSolarChecked');
    trigger('nonSolar.otherValue');
  }, [
    trigger,
    nonSolarHasNonSolarComponent,
    nonSolarRoofRepair,
    nonSolarOther,
    nonSolarEnergyStorage,
    nonSolarEvCharging,
  ]);

  useEffect(() => {
    const installerProduct = account?.products.find(
      (p) => p.id === installerProductId
    );
    setValue(
      'economics.interestRate',
      installerProduct?.product.apr_percentage
    );
    setValue('economics.loanAmount', loanAmount);
  }, [account?.products, loanAmount, installerProductId, setValue]);

  const installerProduct = useMemo(() => {
    return account?.products.find((p) => p.id === installerProductId);
  }, [account?.products, installerProductId]);

  const navigateBackToLoanDetails = async () => {
    setIsLoading(true);
    const [
      loanType,
      y1saving,
      y1production,
      interestRateValue,
      systemSize,
      loanTerm,
      loanProductValue,
      loanSizeValue,
    ] = watch([
      'loanType',
      'application.year_1_total_solar_savings',
      'application.year_1_system_production_kwh',
      'economics.interestRate',
      'application.system_size_in_k_ws',
      'loanTerm',
      'application.loan_product',
      'application.loan_amount',
    ]);
    const mappedLoanType =
      loanType === ProductFamily.SPECIAL_PAYMENT
        ? 'Special Payment'
        : 'Standard';

    const loanTermValue = loanTerm
      ? `${parseInt(loanTerm.slice(0, 2))}%2F${parseInt(loanTerm.slice(-2))}`
      : '';

    const specialPaymentPercentage =
      watch('application.special_payment_percentage') ?? undefined;

    const productId = installerProduct?.product.id;

    const selectedProduct = {
      rate: interestRateValue ?? 0,
      amortizationYears: loanTerm ? parseInt(loanTerm.slice(-2)) : 0,
      loanTermYears: loanTerm ? parseInt(loanTerm.slice(0, 2)) : 0,
      productFamily: loanType,
    };

    const netFundingValue =
      (Number(loanSizeValue) *
        (100 -
          (account?.products?.find((product) => product.id === loanProductValue)
            ?.dealer_fee ?? 0))) /
      100;

    if (selectedProduct) {
      try {
        const monthlyPayment = await sendMonthlyPaymentRequest({
          selectedProduct: {
            ...selectedProduct,
            productFamily: selectedProduct.productFamily as ProductFamily,
          },
          specialPaymentPercentage,
          loanAmount: Number(loanSizeValue),
        });

        const loanDetailsParams = {
          loanSize: loanSizeValue,
          netFunding: netFundingValue,
          loanType: mappedLoanType,
          loanTerm: loanTermValue,
          pricingGrouping:
            `current.${installerProduct?.product_rate_scenario}` ?? '',
          pricingScenario: installerProduct?.product_rate_scenario ?? '',
          interestRate: interestRateValue,
          y1saving: y1saving ? String(y1saving) : undefined,
          y1production: y1production ? String(y1production) : undefined,
          systemSize: systemSize ? String(systemSize) : undefined,
          systemLocation: watch('application.sys_prop_address_state'),
          recInfo: watch('application.rec_info'),
          isFutureRate: 'false',
          specialPaymentPercentage,
          isSpecial: (specialPaymentPercentage ?? 0) > 0 ? 'true' : 'false',
          monthlyPayment,
          installerProductId: loanProductValue,
          productId: productId,
        };

        // We must remove any undefined or null values from the parameters
        const filteredParams = Object.fromEntries(
          Object.entries(loanDetailsParams).filter(
            ([_, v]) => v !== '' && v !== 'null' && v !== 'undefined'
          )
        );

        const searchParams = new URLSearchParams(
          Object.entries(filteredParams).reduce((acc, [key, value]) => {
            if (value != null) {
              acc[key] = String(value);
            }
            return acc;
          }, {} as Record<string, string>)
        ).toString();

        navigate(`/dashboard/loan-details?${searchParams}`);
      } catch (error: any) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  // send a file for uploading and return a promise
  const getFilePromise = (
    applicationId: string,
    file: any,
    documentType: string
  ) => {
    const formData = new FormData();
    formData.append('documentType', documentType);
    formData.append('file', file.file);
    formData.append(
      'required_for_step',
      REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.BORROWER_APP_SUBMISSION
    );
    const filePromise = applicationAPI.uploadApplicationDocument(
      applicationId,
      formData
    );
    return filePromise;
  };

  return (
    <Box
      pt={6.25}
      pb={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      maxWidth="800px"
      px={3}
      mx="auto"
    >
      <Helmet>
        <title>Initialize New Loan Application</title>
      </Helmet>
      <form
        id="newBorrower"
        role={'form'}
        aria-labelledby="newBorrowerFormTitle"
        onSubmit={handleSubmit(async function _handleSubmit(values) {
          try {
            loadingModal.onOpen();
            const {
              loan_proposal: proposalFile,
              utility_bills: utilityBillFiles,
              incentive_documents: incentiveDocumentFiles,
              ...application
            } = values;

            delete application.loanTerm;
            delete application.loanType;
            // Clean Non Solar Amounts for django validation
            application.application.non_solar_amount =
              application.nonSolar?.nonSolarAmount;
            if (!values.application?.rec_info)
              values.application.rec_info = null;
            if (
              !values.nonSolar?.hasNonSolarComponent ||
              values.application.non_solar_amount === ''
            )
              values.application.non_solar_amount = null;
            if (
              !values.nonSolar?.hasNonSolarComponent ||
              values.application.energy_storage_amount === ''
            )
              values.application.energy_storage_amount = null;
            if (
              !values.nonSolar?.hasNonSolarComponent ||
              values.application.ev_charging_amount === ''
            )
              values.application.ev_charging_amount = null;
            if (
              !values.nonSolar?.hasNonSolarComponent ||
              values.application.roof_repair_amount === ''
            )
              values.application.roof_repair_amount = null;
            if (
              !values.nonSolar?.hasNonSolarComponent ||
              values.application.other_non_solar_amount === ''
            )
              values.application.other_non_solar_amount = null;
            // Handle Non Solar MultiSelect
            if (values.nonSolar?.hasNonSolarComponent) {
              const nonSolarComponents =
                (values.nonSolar?.energyStorage ? 'Energy Storage;' : '') +
                (values.nonSolar?.evCharging ? 'EV Charging;' : '') +
                (values.nonSolar?.roofRepair
                  ? 'Roof Repairs/Replacement;'
                  : '') +
                (values.nonSolar?.other
                  ? 'Other: ' +
                    values.nonSolar?.otherValue?.replace(';', ',') +
                    ';'
                  : '');
              application.application.non_solar_components = nonSolarComponents;
            }
            if (application.nonSolar?.hasNonSolarComponent)
              application.application.has_non_solar_component = 'Yes';
            else application.application.has_non_solar_component = 'No';
            delete application.nonSolar;
            // Handle System Mounting Hardware MultiSelect
            const systemMount =
              (values.mounting?.roof ? 'Roof Mount;' : '') +
              (values.mounting?.ground ? 'Ground Mount;' : '') +
              (values.mounting?.carport ? 'Carport;' : '') +
              (values.mounting?.other
                ? 'Other: ' +
                  values.mounting?.otherValue?.replace(';', ',') +
                  ';'
                : '');
            application.application.system_mounting_hardware = systemMount;
            delete application.mounting;
            // Create the application first
            const applicationId = await createBorrowerAccount(application);
            // Upload the loan proposal to the application
            const loanProposalFormData = getFormData(
              proposalFile.file,
              DOCUMENT_TYPE.SOLAR_LOAN_PROPOSAL
            );
            loanProposalFormData.append(
              'required_for_step',
              REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.BORROWER_APP_SUBMISSION
            );

            const promises: Promise<any>[] = [];
            const loanProposalPromise =
              applicationAPI.uploadApplicationDocument(
                applicationId,
                loanProposalFormData
              );
            promises.push(loanProposalPromise);

            utilityBillFiles.map((file) => {
              const filePromise = getFilePromise(
                applicationId,
                file,
                DOCUMENT_TYPE.UTILITY_BILLS
              );
              promises.push(filePromise);
            });

            incentiveDocumentFiles.map((file) => {
              const filePromise = getFilePromise(
                applicationId,
                file,
                DOCUMENT_TYPE.INCENTIVE_DOCUMENTS
              );
              promises.push(filePromise);
            });

            // Wait for all uploads to complete
            await Promise.allSettled(promises);
            // send notification that application has been created successfully
            await applicationAPI.notifyApplicationCreated(applicationId);

            await queryClient.invalidateQueries(INSTALLER_APPS_QUERY);

            setSuccessModalData({
              businessName: application.borrower.name,
              email: application.contact.email,
            });
            loadingModal.onClose();
            successModal.onOpen();
          } catch (err) {
            loadingModal.onClose();
            noticeError(err);
            if (err.data) {
              // Traverse the error object and set the errors
              const errorPaths = traverse(err.data, (val) =>
                Array.isArray(val)
              ) as Parameters<typeof setError>[0][];
              errorPaths.forEach((path) => {
                setError(path, {
                  type: 'custom',
                  message: getAtPath(err.data, path)?.[0],
                });
              });
            }
            toast.error(
              `Error! Borrower not created. ${err.message ? err.message : ''}`
            );
            tracker.track(Tracker.EVENTS.NEW_APPLICANT_ERROR, { error: err });
          }
        })}
      >
        {typeof locationState === 'object' &&
          locationState &&
          'from' in locationState &&
          locationState.from === '/dashboard/loan-details' && (
            <Button
              aria-label="Back to Dashboard"
              variant="text"
              onClick={() => {
                tracker.track(Tracker.EVENTS.NEW_APPLICANT_BACK_CLICKED);
                navigateBackToLoanDetails();
              }}
              sx={{ py: 1, px: 1, ml: -1 }}
              color="inherit"
              startIcon={<ArrowBack fontSize="small" />}
              disabled={isLoading}
            >
              Back to Calculator
            </Button>
          )}
        <Box
          pb={3}
          display="flex"
          flexDirection="column"
          justifyContent="start"
        >
          <Typography variant="h1" id="newBorrowerFormTitle" gutterBottom>
            <b>Initialize New Loan Application</b>
          </Typography>
          <Typography variant="body1">
            Thank you for choosing Sunstone Credit! We look forward to reviewing
            this application.
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">
              {`Please note that if your customer does any of the following they will likely be declined:`}
              <ul>
                <li>Supports the cannabis industry (including CBD and hemp)</li>
                <li>Buys or sells currency (including crypto currency)</li>
                <li>
                  Engages in firearm sales (including the sale of ammunition)
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2} maxWidth={'md'}>
          <Grid item xs={12}>
            <LoanInformation control={control} setValue={setValue} />
          </Grid>
          <Grid item xs={12}>
            <BusinessContactInformationFieldset control={control} />
          </Grid>
          <Grid item xs={12}>
            <SolarProjectInformation
              control={control}
              setError={setError}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12}>
            <NonSolarProjectComponents control={control} setValue={setValue} />
          </Grid>
        </Grid>

        <DialogActions>
          {isSubmitting && <CircularProgress size={24} />}
          <Button
            color="primary"
            disabled={isSubmitting}
            component={RouterLink}
            to={`/${INSTALLER_DASHBOARD_PATH}/`}
            onClick={() => {
              tracker.track(Tracker.EVENTS.NEW_APPLICANT_CANCEL);
            }}
          >
            Cancel
          </Button>
          <div
            style={{
              position: 'relative',
            }}
          >
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={() => {
                tracker.track(Tracker.EVENTS.NEW_APPLICANT_SUBMIT);
              }}
              disabled={isSubmitting || !isValid}
            >
              Add
            </Button>
          </div>
        </DialogActions>
      </form>

      <InfoDialogueWithCtaButton
        open={successModal.isOpen}
        mainIcon={
          <SvgIcon component={DocumentSuccess} sx={{ fontSize: 125 }} />
        }
        heading={`A new loan application has been successfully created for ${successModalData.businessName}`}
        content={
          <>
            <Typography
              variant="body1"
              color="textSecondary"
              textAlign={'center'}
            >
              The loan applicant will receive an email notification at{' '}
              {successModalData.email} with instructions on how to login to the
              Sunstone portal and access this application. Note: The email
              notification may go to spam/junk email inbox if it is not in their
              email inbox.
            </Typography>
            <br />
            <Typography
              variant="body1"
              color="textSecondary"
              textAlign={'center'}
            >
              Please note the borrower will have 45 days to complete and submit
              this application before it expires. We require applications to be
              completed in 45 days to limit any adverse changes to interest
              rates. If there are special circumstances that will require more
              time to complete the application, an extension can be requested by
              the borrower.
            </Typography>
          </>
        }
        ctaText={'Continue to Portal'}
        ctaFunction={() => {
          successModal.onClose;
          navigate(`/${INSTALLER_DASHBOARD_PATH}/`);
        }}
      />
      <InfoDialogueWithCtaButton
        open={loadingModal.isOpen}
        mainIcon={<></>}
        contentContainerStyle={{ width: '515px' }}
        content={
          <div style={{ textAlign: 'center' }}>
            <Lottie
              defaultImage={staticImage}
              animationData={animationData}
              loop={true}
              autoplay={true}
              style={{ marginTop: '-8rem', width: '200px' }}
            />
            <strong>Please do not close this window.</strong>
            <br /> <span>We are creating your application.</span>
          </div>
        }
        ctaFunction={() => {}}
      />
    </Box>
  );
};

const accept = {
  'image/*': [],
  'application/pdf': [],
  'application/doc': ['.doc', '.docx'],
  'application/text': ['.txt'],
};

type SolarProposalFileDropzoneProps = {
  name: keyof Pick<InitialValues, 'loan_proposal'>;
  control: Control<InitialValues>;
  setError: UseFormSetError<InitialValues>;
  label?: ReactNode;
  required?: boolean;
  tooltip?: string;
  uploadText?: ReactNode;
  onRemove?: VoidFunction;
  hideMaxFileSize?: boolean;
};

export const SolarProposalFileDropzone: FC<SolarProposalFileDropzoneProps> = ({
  name,
  control,
  setError,
  label,
  required,
  tooltip,
  uploadText,
  onRemove,
}) => {
  const controller = useController({
    name,
    control,
  });
  const { field, fieldState } = controller;
  const { onChange, value, onBlur } = field;
  const { error, isTouched } = fieldState;

  return (
    <SunstoneDocumentFileDropzone
      file={
        value.file
          ? ({
              name: value.file?.name,
              display_name: value.file?.name,
            } as unknown as SunstoneDocument)
          : undefined
      }
      onDrop={useCallback(
        (accepted, rejected) => {
          if (accepted.length === 0) return;
          const file = accepted[0];

          onChange({
            file,
          });
          onBlur();

          return {
            name: value.file?.name,
            display_name: value.file?.name,
          } as unknown as SunstoneDocument;
        },
        [onBlur, onChange, value.file?.name]
      )}
      onDropRejected={useCallback(
        (rejectedFiles) => {
          const file = rejectedFiles[0].file;
          const error = rejectedFiles[0].errors[0];
          let errorCopy: string;
          if (error.code === 'file-too-large') {
            errorCopy = `${file.name} is too big. Max size is ${bytesToSize(
              maxFileSizeInBytes
            )}.`;
          } else if (error.code === 'file-invalid-type') {
            errorCopy = `${file.name} is not a valid file type. Should be an image, PDF, or a text document.`;
          } else {
            errorCopy = error.message;
          }
          setError(name, {
            file: {
              type: 'custom',
              message: errorCopy,
            },
          } as any);
        },
        [name, setError]
      )}
      label={label}
      maxSize={maxFileSizeInBytes}
      tooltip={tooltip}
      uploadText={uploadText}
      canReplace
      error={isTouched ? (error as any)?.file?.message : null}
      remove={onRemove}
      required={required}
      accept={accept}
    />
  );
};

export default NewBorrowerForm;
