import { Application } from '../../../../../../../../../../types/api';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  companyDocuments: { nonProfitBoardResolution },
  businessTypeForProfitOrNonProfit,
} = loanFormModel.formField;

export class NonProfitBoardResolutionValidation implements ValidationSchema {
  public name = nonProfitBoardResolution.name;
  public label = nonProfitBoardResolution.label;
  public dependsOn: string[] = [businessTypeForProfitOrNonProfit.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    businessTypeForProfitOrNonProfit: Application['business_type_for_profit_or_non_profit'],
    schema
  ) {
    const required =
      businessTypeForProfitOrNonProfit ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.NON_PROFIT;
    if (required)
      return FileNameValidationSchema(
        nonProfitBoardResolution.name,
        true
      ).required('This document is required');
    return FileNameValidationSchema(nonProfitBoardResolution.name, false);
  }
}
export default new Validation(new NonProfitBoardResolutionValidation());
