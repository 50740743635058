import loanFormModel from '../../form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  formField: { systemPropertyAddressState },
} = loanFormModel;

export class PropertyAddressStateValidation implements ValidationSchema {
  public name: string = systemPropertyAddressState.name;
  public label: string = systemPropertyAddressState.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: systemPropertyAddressState.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
}
export default new Validation(new PropertyAddressStateValidation());
