import {
  Application,
  LoanCategory,
} from '../../../../../../../../../../types/api';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import {
  FOR_PROFIT_COMPANY_TYPES,
  genericRequiredFieldError,
} from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModelFlow from '../../form_models/loanFormModel';

const { forProfitCompanyType, loanCategory } = loanFormModel.formField;

const { propertyCompanyUploadRentRollOrProvideTenantInfo } =
  loanFormModelFlow.formField;

export class PropertyCompanyUploadRentRollOrProvideTenantInfoValidation
  implements ValidationSchema
{
  public name = propertyCompanyUploadRentRollOrProvideTenantInfo.name;
  public label = propertyCompanyUploadRentRollOrProvideTenantInfo.label;
  public dependsOn: string[] = [forProfitCompanyType.name, loanCategory.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    forProfitCompanyTypeValue: Application['for_profit_company_type'],
    loanCategoryValue: Application['loan_category'],
    schema
  ) {
    const required =
      forProfitCompanyTypeValue ===
        FOR_PROFIT_COMPANY_TYPES.COLLECTS_RENT_FROM_TENANTS &&
      loanCategoryValue != LoanCategory.EXPRESS_PROPCO;

    if (!required) return schema.notRequired();
    return schema.required(genericRequiredFieldError);
  }
}
export default new Validation(
  new PropertyCompanyUploadRentRollOrProvideTenantInfoValidation()
);
