import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { LIMITS } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: {
    borrowerYearsManagementInPlace,
    borrowerBusinessEstablishedYear,
  },
} = loanFormModel;

export class BorrowerYearsManagementInPlaceValidation
  implements ValidationSchema
{
  public name: string = borrowerYearsManagementInPlace.name;
  public label: string = borrowerYearsManagementInPlace.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [borrowerBusinessEstablishedYear.name];
  public type = 'number';
  public required = {
    message: 'This field is required',
    value: true,
  };
  public nullable = true;

  public when = (borrowerBusinessEstablishedYear, schema) => {
    // This value can't be greater than the difference between the current year and the year the business was established
    const currentYear = new Date().getFullYear();
    // if borrowerBusinessEstablishedYear is a valid year and is at least >= 1900, then we can calculate the max value
    const establishedYear = new Date(
      borrowerBusinessEstablishedYear
    ).getFullYear();
    if (establishedYear && establishedYear >= LIMITS.MIN_YEAR_ALLOWED) {
      const maxYearsManagementInPlace = currentYear - establishedYear;
      return schema.max(
        maxYearsManagementInPlace,
        `Number of Years that Applicant's Present Management has been in Place must be less than or equal to ${maxYearsManagementInPlace}`
      );
    }
    return schema;
  };
}
export default new Validation(new BorrowerYearsManagementInPlaceValidation());
