import { ETHNICITY } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { principalEthnicity } = loanFormModel.formField;

class IsHispanicOrLatino implements Rule {
  private principalId: any;

  public setPrincipalId(id: any) {
    this.principalId = id;
    return this;
  }

  private getPrincipal(data: Partial<ApplicationFormValues>) {
    return data.principals?.find(
      (principal) => principal.id === this.principalId
    );
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const principal = this.getPrincipal(application);

    const ethnicity = principal?.[principalEthnicity.name] || [];
    return ethnicity?.includes(ETHNICITY.HISPANIC_OR_LATINO) ?? false;
  }
}

export default new IsHispanicOrLatino();
