import { ReactElement } from 'react';

type ShowIfProps = {
  every?: boolean[];
  some?: boolean[];
  condition?: boolean;
  children: ReactElement;
};
const ShowIf: React.FC<ShowIfProps> = ({
  children,
  every,
  some,
  condition,
}) => {
  let shouldRender = true;
  if (condition !== undefined) shouldRender &&= condition;
  if (every) shouldRender &&= every.every((condition) => condition);
  if (some) shouldRender &&= some.some((condition) => condition);
  if (shouldRender) return children;
  return null;
};

export default ShowIf;
