import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { NON_PROFIT_ORGANIZATION_TYPES_CHOICES } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

import * as Yup from 'yup';

const {
  formField: { borrowerReligiousParentOrgSupport, nonProfitOrganizationType },
} = loanFormModel;

export class BorrowerReligiousParentOrgSupportValidation
  implements ValidationSchema
{
  public name: string = borrowerReligiousParentOrgSupport.name;
  public label = borrowerReligiousParentOrgSupport.label;
  public dependsOn: string[] = [nonProfitOrganizationType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(nonProfitOrganizationType: string, schema: Yup.StringSchema) {
    if (
      nonProfitOrganizationType ===
      NON_PROFIT_ORGANIZATION_TYPES_CHOICES[0].value
    )
      return schema.required('This field is required');
    return schema;
  }
}
export default new Validation(
  new BorrowerReligiousParentOrgSupportValidation()
);
