import { Control, UseFormSetValue } from 'react-hook-form';
import { Application, RequiredDocuments } from '../../../../../../types/api';

export type ApplicationFormValues = Application & RequiredDocuments;

export type ApplicationStepProps = {
  control: Control<ApplicationFormValues>;
  setValue: UseFormSetValue<ApplicationFormValues>;
};

export enum ApplicationGroups {
  APP_INFO = 'app_info',
  DOC_UPLOADS = 'doc_uploads',
}

export enum ApplicationStepID {
  CONTACT_INFO = 'CONTACT_INFO',
  SYSTEM_LOCATION = 'SYSTEM_LOCATION',
  SYSTEM_POWER = 'SYSTEM_POWER',
  OWNERSHIP_HISTORY = 'OWNERSHIP_HISTORY',
  PRINCIPALS = 'PRINCIPALS',
  REQUIRED_DOCUMENTS = 'REQUIRED_DOCUMENTS',
  BANK_STATEMENTS = 'BANK_STATEMENTS',
  OUTSTANDING_LOANS = 'OUTSTANDING_LOANS',
  REVIEW = 'REVIEW',
  CONSENT = 'CONSENT',
  SOLAR_SYSTEM_PROPERTY_USAGE = 'SOLAR_SYSTEM_PROPERTY_USAGE',
  BUSINESS_INFORMATION = 'BUSINESS_INFORMATION',
  LOAN_OBLIGATIONS = 'LOAN_OBLIGATIONS',
}

export type ApplicationData = {
  application: Application;
  requiredDocuments: RequiredDocuments;
};
