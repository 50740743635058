import loanFormModel from '../../form_models/loanFormModel';
import loanFormModelOld from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO } from '../../../../../../../constants';
import {
  Application,
  LoanCategory,
} from '../../../../../../../../../../types/api';
import { Validation } from '../../../../../../../validations/Validation';

const { propertyCompanyTenantRenewalLength, loanCategory } =
  loanFormModelOld.formField;
const { propertyCompanyUploadRentRollOrProvideTenantInfo } =
  loanFormModel.formField;

export class PropertyCompanyTenantRenewalLengthValidation
  implements ValidationSchema
{
  public name = propertyCompanyTenantRenewalLength.name;
  public label = propertyCompanyTenantRenewalLength.label;
  public dependsOn: string[] = [
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    loanCategory.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public positive = true;
  public nullable = true;

  public when(
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    loanCategory: Application['loan_category'],
    schema
  ) {
    const required =
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO.ENTER_INFORMATION_FOR_SINGLE_TENANT &&
      loanCategory != LoanCategory.EXPRESS_PROPCO;

    if (required) return schema.required('This document is required');
    else return schema.notRequired();
  }
}
export default new Validation(
  new PropertyCompanyTenantRenewalLengthValidation()
);
