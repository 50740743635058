import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: {
    principalRentOrOwn,
    principalIsPG,
    principalMortgagePayment,
    applicantSameAsPrincipal,
  },
} = loanFormModel;

export class PrincipalMortgagePaymentValidation implements ValidationSchema {
  public name = principalMortgagePayment.name;
  public label = principalMortgagePayment.label;
  public dependsOn: string[] = [principalRentOrOwn.name];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalRentOrOwn: string, schema) {
    if (principalRentOrOwn === 'Own')
      return schema.required(principalMortgagePayment.requiredErrorMsg);

    return schema;
  }
}
export default new Validation(new PrincipalMortgagePaymentValidation());
