import { GenericValidation } from '../../../../../../../validations/GenericValidation';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT } from '../../../../../../../constants';

const {
  formField: {
    businessTypeForProfitOrNonProfit,
    nonProfitRecievedBoardApproval,
  },
} = loanFormModel;

export class NonProfitRecievedBoardApprovalValidation
  implements ValidationSchema
{
  public name: string = nonProfitRecievedBoardApproval.name;
  public label: string = nonProfitRecievedBoardApproval.getLabel('Applicant');
  public dependsOn: string[] = [businessTypeForProfitOrNonProfit.name];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: nonProfitRecievedBoardApproval.required,
  };
  public nullable = true;

  when(businessTypeForProfitOrNonProfit: string, schema) {
    if (
      businessTypeForProfitOrNonProfit ===
      BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.NON_PROFIT
    ) {
      return schema;
    }
    return GenericValidation.create({
      name: this.name,
      type: 'string',
      label: this.label,
      required: {
        value: false,
      },
      nullable: true,
    });
  }
}
export default new Validation(new NonProfitRecievedBoardApprovalValidation());
