import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  RulesetResult,
  isExpressLoan,
  IsBetweenJanAndMidFeb,
  IsBetweenMidAprilMidMay,
  IsBetweenMidMayMidAug,
} from '../rules';
import IsBetweenMidFebMidApril from '../rules/IsBetweenMidFebMidApril';
import IsBusinessEstablishedEqualTo from '../rules/IsBusinessEstablishedEqualTo';
import IsBusinessEstablishedLessThan from '../rules/IsBusinessEstablishedLessThan';

class FinancialStatementQ1Ruleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const businessEstablishedTwoYearsAgo =
      IsBusinessEstablishedEqualTo.setYears(2).evaluate(data);
    const businessEstablishedLessThanTwoYearsAgo =
      IsBusinessEstablishedLessThan.setYears(2).evaluate(data);
    const isBetweenJanAndMidMay = [
      IsBetweenJanAndMidFeb,
      IsBetweenMidFebMidApril,
      IsBetweenMidAprilMidMay,
    ].some((Is) => Is.evaluate(data));

    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);

    if (businessEstablishedTwoYearsAgo && !hasApplicantEverFiledTR)
      if (isBetweenJanAndMidMay) return RulesetResult.OPTIONAL;
      else if (IsBetweenMidMayMidAug.evaluate(data))
        return RulesetResult.REQUIRED;
      else return RulesetResult.HIDDEN;
    else if (businessEstablishedLessThanTwoYearsAgo && !hasApplicantEverFiledTR)
      if (isBetweenJanAndMidMay || IsBetweenMidMayMidAug.evaluate(data))
        return RulesetResult.OPTIONAL;
      else return RulesetResult.HIDDEN;

    if (hasApplicantEverFiledTR && isExpressLoan.evaluate(data))
      return RulesetResult.HIDDEN;

    if (hasApplicantEverFiledTR && isBetweenJanAndMidMay)
      return RulesetResult.OPTIONAL;
    else if (hasApplicantEverFiledTR && IsBetweenMidMayMidAug.evaluate(data))
      return RulesetResult.REQUIRED;
    return RulesetResult.HIDDEN;
  };
}

export default new FinancialStatementQ1Ruleset();
