import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { FinancialStatementQ2Ruleset } from '../../business/ruleset';
import loanFormModel, { taxDocuments } from '../../form_models/loanFormModel';

const { financialStatementQ2, applicantFiledTaxReturnsInThePast } =
  taxDocuments;
const { borrowerBusinessEstablishedYear, loanCategory } =
  loanFormModel.formField;
// uid: <BWR-FIN_STMT_YTD_Q2-FILE>
export class FinancialStatementQ2Validation implements ValidationSchema {
  public name = financialStatementQ2.name;
  public label = financialStatementQ2.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    borrowerBusinessEstablishedYear.name,
    applicantFiledTaxReturnsInThePast.name,
    loanCategory.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = FinancialStatementQ2Ruleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(financialStatementQ2.name, required);
  }
}
export default new Validation(new FinancialStatementQ2Validation());
