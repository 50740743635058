import loanFormModel from '../../form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  formField: { systemPropertyAddressZipCode },
} = loanFormModel;

export class PropertyAddressZipCodeValidation implements ValidationSchema {
  public name: string = systemPropertyAddressZipCode.name;
  public label: string = systemPropertyAddressZipCode.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: systemPropertyAddressZipCode.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
}
export default new Validation(new PropertyAddressZipCodeValidation());
