import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { TR4YearsAgoRuleset } from '../../business/ruleset';
import loanFormModel, { taxDocuments } from '../../form_models/loanFormModel';

const { taxReturns4Year, applicantFiledTaxReturnsInThePast } = taxDocuments;
const { loanCategory } = loanFormModel.formField;

export class TaxReturns3YearValidation implements ValidationSchema {
  public name = taxReturns4Year.name;
  public label = taxReturns4Year.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledTaxReturnsInThePast.name,
    loanCategory.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = TR4YearsAgoRuleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(taxReturns4Year.name, required);
  }
}
export default new Validation(new TaxReturns3YearValidation());
