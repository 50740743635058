import {
  Application,
  LoanCategory,
} from '../../../../../../../../../../types/api';
import loanFormModelOld from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../form_models/loanFormModel';

const { propertyCompanyUploadRentRollOrProvideTenantInfo } =
  loanFormModel.formField;

const { propertyCompanyTenantPaymentsSatisfactory, loanCategory } =
  loanFormModelOld.formField;

export class PropertyCompanyTenantPaymentsSatisfactoryValidation
  implements ValidationSchema
{
  public name = propertyCompanyTenantPaymentsSatisfactory.name;
  public label = propertyCompanyTenantPaymentsSatisfactory.label;
  public dependsOn: string[] = [
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    loanCategory.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    loanCategory: Application['loan_category'],
    schema
  ) {
    const required =
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO.ENTER_INFORMATION_FOR_SINGLE_TENANT &&
      loanCategory != LoanCategory.EXPRESS_PROPCO;

    if (!required) return schema.notRequired();
    return schema.required('This field is required');
  }
}
export default new Validation(
  new PropertyCompanyTenantPaymentsSatisfactoryValidation()
);
