import * as Yup from 'yup';
import PgTaxes1YearAgoValidation from '../../../../../../../validations/required_documents/PgTaxes1YearAgoValidation';
import PgExtension1YearAgoValidation from '../../../../../../../validations/required_documents/PgExtension1YearAgoValidation';
import PgTaxes2YearsAgoValidation from '../../../../../../../validations/required_documents/PgTaxes2YearsAgoValidation';
import PgTaxes3YearsAgoValidation from '../../../../../../../validations/required_documents/PgTaxes3YearsAgoValidation';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

export class PrincipalsStage2Validation implements ValidationSchema {
  public name = 'principals';
  public label = '';
  public dependsOn: string[] = [];
  public type = 'array';
  public required = {
    message: '',
    value: false,
  };
  public nullable = false;

  public when(schema: Yup.ArraySchema<Yup.ObjectSchema<any>>) {
    return schema.of(
      Yup.object().shape({
        documents: Yup.object().shape({
          [PgTaxes1YearAgoValidation.name]:
            PgTaxes1YearAgoValidation.buildSchema(),
          [PgExtension1YearAgoValidation.name]:
            PgExtension1YearAgoValidation.buildSchema(),
          [PgTaxes2YearsAgoValidation.name]:
            PgTaxes2YearsAgoValidation.buildSchema(),
          [PgTaxes3YearsAgoValidation.name]:
            PgTaxes3YearsAgoValidation.buildSchema(),
        }),
      })
    );
  }
}
export default new Validation(new PrincipalsStage2Validation());
