import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModelNew from '../../form_models/loanFormModel';
import { BusinessRuleBase } from '../../../../../../../business/BusinessBase';

const {
  formField: { propertyOwnerPercentOwned },
} = loanFormModelNew;

export class PropertyOwnerPercentOwnershipValidation
  implements ValidationSchema
{
  public name = propertyOwnerPercentOwned.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when = (schema) => {
    return schema.test(
      'check-property-percent-owned-required',
      'This field is required',
      function (value) {
        const application = this.from[1].value; // this is a relative path
        const bl = new BusinessRuleBase(application);
        if (bl.isLeased() && bl.isPropertyOwnerRelatedToBorrowerBusiness()) {
          return value != undefined && value != null && value !== '';
        }
        return true;
      }
    );
  };
}
export default new Validation(new PropertyOwnerPercentOwnershipValidation());
