import loanFormModel from '../../form_models/loanFormModel';
import loanFormModelOld from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { Application } from '../../../../../../../../../../types/api';
import { RequiredDocumentsBL } from '../../../../../../../business/RequiredDocumentsBL';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  systemPropertyCurrentlyPayingMortgage,
  companyDocuments: { pgMortgageStatement },
} = loanFormModel.formField;

const { loanCategory } = loanFormModelOld.formField;

export class PgMortgageStatementValidation implements ValidationSchema {
  public name = pgMortgageStatement.name;
  public label = '';
  public dependsOn: string[] = [
    loanCategory.name,
    systemPropertyCurrentlyPayingMortgage.name,
  ];
  public type = 'mixed';
  public required = {
    message: '',
    value: false,
  };

  public when(
    loanCategoryValue: Application['loan_category'],
    isPayingMortgage: Application['borrower_is_currently_paying_mortgage'],
    schema
  ) {
    const bl = new RequiredDocumentsBL({
      [systemPropertyCurrentlyPayingMortgage.name]: isPayingMortgage,
      [loanCategory.name]: loanCategoryValue,
    } as any);
    const required =
      (bl.isExpressLoan() || bl.isExpressPlusLoan()) &&
      isPayingMortgage === 'Yes';

    if (required)
      return FileNameValidationSchema(pgMortgageStatement.name, true).required(
        'This document is required'
      );
    return FileNameValidationSchema(pgMortgageStatement.name, false).nullable();
  }
}
export default new Validation(new PgMortgageStatementValidation());
