import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalAddress },
} = loanFormModel;

export class PrincipalAddressValidation implements ValidationSchema {
  public name = principalAddress.name;
  public label = principalAddress.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: principalAddress.requiredErrorMsg,
    value: principalAddress.required,
  };
  public nullable = true;
}
export default new Validation(new PrincipalAddressValidation());
