import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalOccupation, principalOtherOccupation },
} = loanFormModel;

export class PrincipalOtherOccupationValidation implements ValidationSchema {
  public name = principalOtherOccupation.name;
  public label = principalOtherOccupation.label;
  public dependsOn: string[] = [principalOccupation.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalOccupation: string, schema) {
    if (principalOccupation === 'Other') {
      return schema.required(principalOtherOccupation.requiredErrorMsg);
    }
    return schema.notRequired();
  }
}
export default new Validation(new PrincipalOtherOccupationValidation());
