import * as Yup from 'yup';

import {
  bankStatementsValidation,
  companyDocumentsValidation,
  hoaDocumentsValidation,
  solarSystemDocumentsValidation,
  additionalDocumentationValidation,
} from './requiredDocumentsValidation';

import {
  ApplicantFiledTaxReturnsInThePastValidation,
  IrsFormSS4Validation,
} from '../../../../../validations/required_documents';

import {
  PrincipalsStage2Validation,
  FinancialStatementLastYearQ3Validation,
  FinancialStatementQ1Validation,
  FinancialStatementQ2Validation,
  FinancialStatementQ3Validation,
  ApplicantFiledLastYearTaxReturnsValidation,
  TaxReturnsExtensionValidation,
  FinancialStatementFullYearValidation,
  TaxReturnsLastYearValidation,
  TaxReturns3YearValidation,
  TaxReturns4YearValidation,
  TaxReturns2YearValidation,
} from './validations/required_documents';

import {
  PropertyAddressValidation,
  PropertyAddressCityValidation,
  PropertyAddressStateValidation,
  PropertyAddressZipCodeValidation,
  PropertyAppraisedValueValidation,
  PropertyOwnershipStatusValidation,
  PropertyLegalNameOfOwnerValidation,
  PropertyOwnerAffiliatedWithBorrowerValidation,
  PropertyLeaseExpirationYearValidation,
} from './validations/solar_system_property';

import {
  OutstandingLoanValidation,
  PropertyLandlordConsentValidation,
  PropertyAddressIsSameAsBusinessValidation,
  PropertyCurrentlyPayingMortgageValidation,
} from '../../../../../validations/solar_system_property';

import {
  SingleOrMultipleUsersValidation,
  CommunityOrPPALeaseValidation,
  LegalNamesOfOfftakersValidation,
  NumberOfOfftakersValidation,
  PowerTaxEquityPartneringValidation,
  TaxEquityFundingPercentageValidation,
  OfftakerNeedsPercentageValidation,
  OfftakerUtilityRateDiscountValidation,
  SubscribersAmountValidation,
  SubscribersDiscountValidation,
  UtilityJurisdictionNameValidation,
  SubscriberManagementCompanyValidation,
  NonProfitRecievedBoardApprovalValidation,
} from '../../../../../validations/solar_system_power';
import {
  BusinessAddressValidation,
  BusinessAddressCityValidation,
  BusinessAddressStateValidation,
  BusinessAddressZipCodeValidation,
  MailingAddressSameAsBusinessPicklistValidation,
  MailingAddressValidation,
  MailingAddressCityValidation,
  MailingAddressStateValidation,
  MailingAddressZipCodeValidation,
} from '../../../../../validations/borrower_information';

import {
  BorrowerTinValidation,
  BorrowerGrossRevenueLatestValidation,
  BorrowerBusinessEstablishedYearValidation,
  BorrowerYearsManagementInPlaceValidation,
  BorrowerManagementExperienceYearsValidation,
  BorrowerLinesOfBusinessValidation,
} from '../../../../../validations/ownership_history';

import { BorrowerBusinessStructureValidation } from './validations/ownership_history';

import {
  BorrowerReligiousParentOrgSupportValidation,
  BorrowerReligiousLeaderYearsValidation,

  // TODO: Uncomment these back when CRB requires it
  BusinessOwnershipStatusValidation,
  NumberOfEmployeesRangeValidation,
} from './validations/business_information';

import PrincipalsValidation from './validations/principals/PrincipalsValidation';

import {
  calculatePGPercentOwnership,
  missingApplicantSameAsPrincipal,
  requiresPGPercentOwnership,
} from '../../../../../components/loan_application/utils/helpers';

import { PG_PERCENT_OWNERSHIP_FLOOR } from '../../../../../constants';

// Solar System Property Usage

import { SystemPowerUsageValidation } from './validations/solar_system_power';

export const solarSystemPropertyUsage = [
  // Solar System Location
  PropertyAddressValidation,
  PropertyAddressCityValidation,
  PropertyAddressStateValidation,
  PropertyAddressZipCodeValidation,

  // Solar System Property
  PropertyAppraisedValueValidation,
  PropertyOwnershipStatusValidation,
  PropertyLegalNameOfOwnerValidation,
  PropertyOwnerAffiliatedWithBorrowerValidation,
  PropertyLandlordConsentValidation,
  PropertyLeaseExpirationYearValidation,
  OutstandingLoanValidation,

  // PPA / Community Solar
  SystemPowerUsageValidation,
  SingleOrMultipleUsersValidation,
  CommunityOrPPALeaseValidation,
  LegalNamesOfOfftakersValidation,
  NumberOfOfftakersValidation,
  PowerTaxEquityPartneringValidation,
  TaxEquityFundingPercentageValidation,
  OfftakerNeedsPercentageValidation,
  OfftakerUtilityRateDiscountValidation,
  SubscribersAmountValidation,
  SubscribersDiscountValidation,
  UtilityJurisdictionNameValidation,
  SubscriberManagementCompanyValidation,
  PropertyCurrentlyPayingMortgageValidation,
].reduce((acc, validation) => {
  acc[validation.name] = validation.buildSchema();
  return acc;
}, {});

export const solarSystemPropertyUsageSchema = Yup.object().shape(
  solarSystemPropertyUsage
);

// Business Information
export const businessInformation = [
  // Business
  PropertyAddressIsSameAsBusinessValidation,
  BusinessAddressValidation,
  BusinessAddressCityValidation,
  BusinessAddressStateValidation,
  BusinessAddressZipCodeValidation,
  // Mailing
  MailingAddressSameAsBusinessPicklistValidation,
  MailingAddressValidation,
  MailingAddressCityValidation,
  MailingAddressStateValidation,
  MailingAddressZipCodeValidation,

  // Business Details
  BorrowerBusinessStructureValidation,
  BorrowerTinValidation,
  BorrowerGrossRevenueLatestValidation,
  BorrowerBusinessEstablishedYearValidation,
  BorrowerYearsManagementInPlaceValidation,
  BorrowerManagementExperienceYearsValidation,

  BorrowerLinesOfBusinessValidation,
  NonProfitRecievedBoardApprovalValidation,
  BorrowerReligiousParentOrgSupportValidation,
  BorrowerReligiousLeaderYearsValidation,

  // TODO: Uncomment these back when CRB requires it
  // BusinessOwnershipStatusValidation,
  // NumberOfEmployeesRangeValidation,
].reduce((acc, validation) => {
  acc[validation.name] = validation.buildSchema();
  return acc;
}, {});

export const businessInformationSchema =
  Yup.object().shape(businessInformation);

export const principals = {
  [PrincipalsValidation.name]: PrincipalsValidation.buildSchema(),
  pgPercentageRule: Yup.string()
    .nullable()
    .test(
      'validate-percentage-rule',
      'check percentage requirement',
      function (value) {
        const application = this.from[0].value;
        // Special handling for Principals Information page
        // disable the next step if it's a PG loan and the % ownership of PGs is not >= PG_PERCENT_OWNERSHIP_FLOOR
        return (
          !requiresPGPercentOwnership(application) ||
          (calculatePGPercentOwnership(application.principals) >=
            PG_PERCENT_OWNERSHIP_FLOOR &&
            // We also need to check that the field applicantSameAsPrincipal is filled as the logic overrides the step completion,
            // marking the step as completed even if the field is empty
            !missingApplicantSameAsPrincipal(application))
        );
      }
    ),
};

export const principalsSchema = Yup.object().shape(principals);

const taxDocumentsValidation = {
  [ApplicantFiledTaxReturnsInThePastValidation.name]:
    ApplicantFiledTaxReturnsInThePastValidation.buildSchema(),
  [ApplicantFiledLastYearTaxReturnsValidation.name]:
    ApplicantFiledLastYearTaxReturnsValidation.buildSchema(),
  [IrsFormSS4Validation.name]: IrsFormSS4Validation.buildSchema(),
  [TaxReturnsExtensionValidation.name]:
    TaxReturnsExtensionValidation.buildSchema(),
  [TaxReturns2YearValidation.name]: TaxReturns2YearValidation.buildSchema(),
  [TaxReturns3YearValidation.name]: TaxReturns3YearValidation.buildSchema(),
  [TaxReturns4YearValidation.name]: TaxReturns4YearValidation.buildSchema(),
  [TaxReturnsLastYearValidation.name]:
    TaxReturnsLastYearValidation.buildSchema(),
  [FinancialStatementQ1Validation.name]:
    FinancialStatementQ1Validation.buildSchema(),
  [FinancialStatementQ2Validation.name]:
    FinancialStatementQ2Validation.buildSchema(),
  [FinancialStatementQ3Validation.name]:
    FinancialStatementQ3Validation.buildSchema(),
  [FinancialStatementLastYearQ3Validation.name]:
    FinancialStatementLastYearQ3Validation.buildSchema(),
  [FinancialStatementFullYearValidation.name]:
    FinancialStatementFullYearValidation.buildSchema(),
};

export const principalsDocumentsValidation = {
  [PrincipalsStage2Validation.name]: PrincipalsStage2Validation.buildSchema(),
};

export const documentsValidation = {
  ...taxDocumentsValidation,
  ...companyDocumentsValidation,
  ...hoaDocumentsValidation,
  ...solarSystemDocumentsValidation,
  ...additionalDocumentationValidation,
  ...principalsDocumentsValidation,
  ...bankStatementsValidation,
};

export const documentsValidationSchema =
  Yup.object().shape(documentsValidation);
