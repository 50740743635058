import {
  LoanCategory,
  PlaidAssetReport,
  YesNo,
} from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { SYSTEM_POWER_USAGE_TYPE } from '../../constants';
import { Validation } from '../Validation';
import { GenericValidation } from '../GenericValidation';
import { ValidationSchema } from '../interfaces';
import * as Yup from 'yup';
import { BankStatementsBL } from '../../business/BankStatementsBL';
const {
  formField: {
    loanCategory,
    isPG,
    systemPowerUsage,
    principalAverageCashBalance,
    documents: { bankStatement3Month, plaidAssetReport },
  },
} = loanFormModel;

export class BankStatements3MonthValidation implements ValidationSchema {
  public name: string = bankStatement3Month.name;
  public label: string = bankStatement3Month.label;
  public dependsOn: string[] = [
    plaidAssetReport.name,
    systemPowerUsage.name,
    isPG.name,
    `principals[0].${principalAverageCashBalance.name}`,
    loanCategory.name,
  ];
  public type = 'string';
  public required = {
    message: 'Required document missing',
    value: bankStatement3Month.required,
  };
  public nullable = true;

  public when = (
    assetReport: PlaidAssetReport,
    systemPowerUsageValue: SYSTEM_POWER_USAGE_TYPE,
    isPGValue: YesNo,
    averageCashBalance: number,
    loanCategory: LoanCategory,
    schema
  ): any => {
    const bl = new BankStatementsBL({
      [plaidAssetReport.name]: assetReport,
      [systemPowerUsage.name]: systemPowerUsageValue,
      [isPG.name]: isPGValue,
      principals: [
        { [principalAverageCashBalance.name]: averageCashBalance } as any,
      ],
    });
    // When asset report is present,
    //  or systemPowerUsage is "Sold to another entity"
    //  or isPersonalGuarantor is "Yes",
    // don't require bank statements
    const isExpressPlusLoan = [
      LoanCategory.EXPRESS_PLUS_OPCO,
      LoanCategory.EXPRESS_PLUS_PROPCO,
    ].includes(loanCategory);

    if (isExpressPlusLoan || !bl.isBankStatementRequired()) {
      return Yup.object().shape({
        files: Yup.array().of(
          Yup.object().shape({
            file_name: GenericValidation.create({
              name: this.name,
              type: 'string',
              label: this.label,
              nullable: true,
            }),
          })
        ),
      });
    }
    return schema;
  };

  public buildShape(schema) {
    return Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object()
            .shape({
              file_name: schema,
            })
            .required()
        )
        .min(1)
        .required(),
    });
  }
}

export default new Validation(new BankStatements3MonthValidation());
