import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  IsBetweenMidAprilMidMay,
  IsBetweenMidAugMidOct,
  IsBetweenMidMayMidAug,
  RulesetResult,
} from '../rules';

class ApplicantFiledTRLastYearRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);
    if (
      hasApplicantEverFiledTR &&
      [
        IsBetweenMidAprilMidMay,
        IsBetweenMidMayMidAug,
        IsBetweenMidAugMidOct,
      ].some((Is) => Is.evaluate(data))
    )
      return RulesetResult.REQUIRED;
    return RulesetResult.HIDDEN;
  };
}

export default new ApplicantFiledTRLastYearRuleset();
