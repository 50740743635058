import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { businessAddress },
} = loanFormModel;

export class BusinessAddressValidation implements ValidationSchema {
  public name: string = businessAddress.name;
  public label: string = businessAddress.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: 'Street Address is a required field',
    value: businessAddress.required,
  };
  public nullable = true;
}
export default new Validation(new BusinessAddressValidation());
