import { RELATED_PARTY_TYPES } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalType, principalLastName },
} = loanFormModel;

export class PrincipalLastNameValidation implements ValidationSchema {
  public name = principalLastName.name;
  public label = principalLastName.label;
  public dependsOn: string[] = [principalType.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, schema) {
    if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
      return schema.required(principalLastName.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new PrincipalLastNameValidation());
