import { defaultApplicantName } from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { TRExtension7004Ruleset } from '../../business/ruleset';
import { taxDocuments } from '../../form_models/loanFormModel';

const {
  taxReturnsExtension,
  applicantFiledTaxReturnsInThePast,
  applicantFiledLastYearTaxReturns,
} = taxDocuments;

export class TaxReturnsExtensionValidation implements ValidationSchema {
  public name = taxReturnsExtension.name;
  public label = taxReturnsExtension.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    applicantFiledTaxReturnsInThePast.name,
    applicantFiledLastYearTaxReturns.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = TRExtension7004Ruleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const required = !!(result & RulesetResult.REQUIRED);
    return FileNameValidationSchema(taxReturnsExtension.name, required);
  }
}
export default new Validation(new TaxReturnsExtensionValidation());
