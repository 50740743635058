import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  IsBetweenMidAprilMidMay,
  IsBetweenMidAugMidOct,
  IsBetweenMidMayMidAug,
  RulesetResult,
  HasApplicantFiledTRLastYear,
} from '../rules';
import IsBusinessEstablishedEqualTo from '../rules/IsBusinessEstablishedEqualTo';

// @see https://sunstonecreditinc.atlassian.net/browse/SUNENG-2200
// Rules are separated by express/non-express loans. However, the rules are the same for both.
class TRExtension7004Ruleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const isBetweenMidAprilAndMidOct = [
      IsBetweenMidAprilMidMay,
      IsBetweenMidMayMidAug,
      IsBetweenMidAugMidOct,
    ].some((Is) => Is.evaluate(data));
    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);
    const businessEstablishedTwoYearsAgo =
      IsBusinessEstablishedEqualTo.setYears(2).evaluate(data);
    if (
      !hasApplicantEverFiledTR &&
      isBetweenMidAprilAndMidOct &&
      businessEstablishedTwoYearsAgo
    )
      return RulesetResult.REQUIRED;

    if (
      hasApplicantEverFiledTR &&
      isBetweenMidAprilAndMidOct &&
      !HasApplicantFiledTRLastYear.evaluate(data)
    )
      return RulesetResult.REQUIRED;
    return RulesetResult.HIDDEN;
  };
}

export default new TRExtension7004Ruleset();
