import {
  Application,
  LoanCategory,
} from '../../../../../../../../../../types/api';
import { PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import loanFormModel from '../../form_models/loanFormModel';
import loanFormModelOld from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { Validation } from '../../../../../../../validations/Validation';

const { propertyCompanyUploadRentRollOrProvideTenantInfo } =
  loanFormModel.formField;

const { propertyCompanyTenantMoveInYear, loanCategory } =
  loanFormModelOld.formField;

export class PropertyCompanyTenantMoveInYearValidation
  implements ValidationSchema
{
  public name = propertyCompanyTenantMoveInYear.name;
  public label = propertyCompanyTenantMoveInYear.label;
  public dependsOn: string[] = [
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    loanCategory.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public positive = true;
  public min = {
    message: 'Year must be greater than 1900',
    value: 1900,
  };
  public max = {
    message: 'Year must be less than or equal to current year',
    value: new Date().getFullYear(),
  };
  public nullable = true;

  public when(
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    loanCategory: Application['loan_category'],
    schema
  ) {
    const required =
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO.ENTER_INFORMATION_FOR_SINGLE_TENANT &&
      loanCategory != LoanCategory.EXPRESS_PROPCO;

    if (required) return schema.required('This document is required');
    else return schema.notRequired();
  }
}
export default new Validation(new PropertyCompanyTenantMoveInYearValidation());
