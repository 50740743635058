import { BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { businessTypeForProfitOrNonProfit } = loanFormModel.formField;

class IsForProfit implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const businessType =
      application[businessTypeForProfitOrNonProfit.name] || '';
    return (
      businessType?.includes(
        BUSINESS_TYPE_FOR_PROFIT_OR_NON_PROFIT.FOR_PROFIT
      ) ?? false
    );
  }
}

export default new IsForProfit();
