import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  RulesetResult,
  isExpressLoan,
  IsBetweenMidAugMidOct,
  IsBetweenMidOctMidNov,
  IsBetweenJanAndMidFeb,
  IsBetweenMidAprilMidMay,
  IsBetweenMidMayMidAug,
  IsBetweenMidNovAndDec,
} from '../rules';
import IsBetweenMidFebMidApril from '../rules/IsBetweenMidFebMidApril';
import IsBusinessEstablishedEqualTo from '../rules/IsBusinessEstablishedEqualTo';
import IsBusinessEstablishedLessThan from '../rules/IsBusinessEstablishedLessThan';

class FinancialStatementQ2Ruleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const businessEstablishedTwoYearsAgo =
      IsBusinessEstablishedEqualTo.setYears(2).evaluate(data);
    const businessEstablishedLessThanTwoYearsAgo =
      IsBusinessEstablishedLessThan.setYears(2).evaluate(data);

    const isBetweenMidAugAndMidNov = [
      IsBetweenMidAugMidOct,
      IsBetweenMidOctMidNov,
    ].some((Is) => Is.evaluate(data));

    const isBetweenJanAndMidAug = [
      IsBetweenJanAndMidFeb,
      IsBetweenMidFebMidApril,
      IsBetweenMidAprilMidMay,
      IsBetweenMidMayMidAug,
    ].some((Is) => Is.evaluate(data));
    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);

    if (
      (businessEstablishedTwoYearsAgo ||
        businessEstablishedLessThanTwoYearsAgo) &&
      !hasApplicantEverFiledTR
    ) {
      if (isBetweenJanAndMidAug || IsBetweenMidNovAndDec.evaluate(data)) {
        return RulesetResult.HIDDEN;
      } else {
        return businessEstablishedTwoYearsAgo
          ? RulesetResult.REQUIRED
          : RulesetResult.OPTIONAL;
      }
    }

    if (hasApplicantEverFiledTR && isExpressLoan.evaluate(data))
      return RulesetResult.HIDDEN;

    if (hasApplicantEverFiledTR && isBetweenMidAugAndMidNov)
      return RulesetResult.REQUIRED;

    return RulesetResult.HIDDEN;
  };
}

export default new FinancialStatementQ2Ruleset();
