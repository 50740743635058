import { BusinessRuleBase } from '../../../../../../../business/BusinessBase';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';
import { GenericValidation } from '../../../../../../../validations/GenericValidation';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import * as Yup from 'yup';
import { Validation } from '../../../../../../../validations/Validation';
import { LoanCategory } from '../../../../../../../../../../types/api';

const {
  formField: {
    loanCategory,
    documents: { bankStatement3Month },
  },
} = loanFormModel;

export class BankStatements3MonthValidation implements ValidationSchema {
  public name: string = bankStatement3Month.name;
  public label: string = bankStatement3Month.label;
  public dependsOn: string[] = [loanCategory.name];
  public type = 'string';
  public required = {
    message: 'Required document missing',
    value: bankStatement3Month.required,
  };
  public nullable = true;

  public when = (category: LoanCategory, schema): any => {
    const bl = new BusinessRuleBase({
      [loanCategory.name]: category,
    });

    if (bl.isExpressLoan() || bl.isExpressPlusLoan()) {
      return Yup.object().shape({
        files: Yup.array().of(
          Yup.object().shape({
            file_name: GenericValidation.create({
              name: this.name,
              type: 'string',
              label: this.label,
              nullable: true,
            }),
          })
        ),
      });
    }
    return schema;
  };

  public buildShape(schema) {
    return Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object()
            .shape({
              file_name: schema,
            })
            .required()
        )
        .min(1)
        .required(),
    });
  }
}

export default new Validation(new BankStatements3MonthValidation());
