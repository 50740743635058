import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: {
    principalRentOrOwn,
    principalIsPG,
    principalRent,
    applicantSameAsPrincipal,
  },
} = loanFormModel;

export class PrincipalRentValidation implements ValidationSchema {
  public name = principalRent.name;
  public label = principalRent.label;
  public dependsOn: string[] = [
    principalIsPG.name,
    principalRentOrOwn.name,
    applicantSameAsPrincipal.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    principalIsPG: string,
    principalRentOrOwn: string,
    applicantSameAsPrincipal: string,
    schema
  ) {
    if (
      principalIsPG === 'Yes' &&
      applicantSameAsPrincipal === 'Yes' &&
      principalRentOrOwn === 'Rent'
    ) {
      return schema.required(principalRent.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new PrincipalRentValidation());
