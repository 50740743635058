import { cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { ValidationSchema } from './interfaces';

export class Validation {
  private schema: ValidationSchema;
  name: string;

  constructor(schema: ValidationSchema) {
    this.schema = schema;
    this.name = schema.name;
  }

  public buildSchema() {
    let schema: any = this.getType();

    if (this.schema.matches)
      schema = schema.matches(
        this.schema.matches.value,
        this.schema.matches.message
      );

    if (this.schema.positive && schema instanceof Yup.NumberSchema)
      schema = schema.positive();

    if (this.schema.positive) schema = schema.positive();

    if (this.schema.nullable) schema = schema.nullable() as any;
    if (this.schema.optional) schema = schema.optional() as any;
    if (this.schema.required?.value) {
      schema = schema.required(this.schema.required.message);
    }

    if (this.schema.min)
      schema = schema.min(this.schema.min.value, this.schema.min.message);

    if (this.schema.max)
      schema = schema.max(this.schema.max.value, this.schema.max.message);

    if (this.schema.nullable) schema = schema.nullable() as any;

    if (this.schema.buildShape) schema = this.schema.buildShape(schema);

    if (this.schema.when) {
      return schema.when(this.schema.dependsOn, (...args: any[]) => {
        let result: any = null;
        if (this.schema.ruleset) {
          const data = {};
          this.schema.dependsOn.forEach((key, index) => {
            data[key] = args[index];
          });
          result = this.schema.ruleset.evaluate(data);
        }
        if (this.schema.when) {
          return this.schema.when(...args, result);
        }
        return schema;
      });
    }

    return schema;
  }

  public getType() {
    if (this.schema.type === 'mixed') {
      return Yup.mixed();
    } else if (this.schema.type === 'object') {
      return Yup.object();
    } else if (this.schema.type === 'string') {
      let schema = Yup.string();
      if (this.schema.typeError?.value) {
        schema = schema.typeError(this.schema.typeError.message);
      }
      return schema;
    } else if (this.schema.type === 'boolean') {
      return Yup.boolean();
    } else if (this.schema.type === 'date') {
      let schema = Yup.date();
      if (this.schema.typeError?.value) {
        schema = schema.typeError(this.schema.typeError.message);
      }
      return schema;
    } else if (this.schema.type === 'number') {
      let schema = Yup.number();
      if (this.schema.typeError?.value) {
        schema = schema.typeError(this.schema.typeError.message);
      }
      return schema;
    } else if (this.schema.type === 'array') {
      return Yup.array();
    } else if (this.schema.type === 'email') {
      return (
        Yup.string()
          .email('Invalid email address')
          // Test thet top level domain is 2 to 6 characters long
          .test('has-valid-tdn', 'Invalid email address', (value) =>
            value ? /\.[A-Za-z]{2,6}$/.test(value) : false
          )
      );
    }
    return Yup.string();
  }

  protected clone(data: any) {
    const _this = cloneDeep(this);
    if (data.required) {
      if (data.required.value !== undefined)
        _this.schema.required.value = false;
    }
    return _this;
  }
}
