import { SvgIcon, SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';
import WarningIcon from './WarningIcon.svg';
import SuccessIcon from './SuccessIcon.svg';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

interface AlertIconProps extends SvgIconProps {
  alertType: 'success' | 'warning' | 'error' | 'info';
  iconColor?: string;
}

const AlertIcon = forwardRef<SVGSVGElement, AlertIconProps>(function AlertIcon(
  { alertType, iconColor, ...props },
  ref
) {
  const Icon = () => {
    switch (alertType) {
      case 'success':
        return <SuccessIcon />;
      case 'warning':
        return <WarningIcon color={iconColor ?? '#EBBA5B'} />;
      case 'info':
        return <WarningIcon />;
      case 'error':
        return <ErrorRoundedIcon color="error" />;
      default:
        return null;
    }
  };

  return (
    <SvgIcon ref={ref} viewBox="0 0 55 55" {...props}>
      <Icon />
    </SvgIcon>
  );
});

export default AlertIcon;
