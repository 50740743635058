import loanFormModel from '../../form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import * as Yup from 'yup';

const {
  formField: { systemPropertyOwnershipStatus, systemPropertyAppraisedValue },
} = loanFormModel;

export class PropertyAppraisedValueValidation implements ValidationSchema {
  public name: string = systemPropertyAppraisedValue.name;
  public label = '';
  public dependsOn: string[] = [systemPropertyOwnershipStatus.name];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(ownershipType: string, schema: Yup.NumberSchema) {
    if (ownershipType === 'OWNED')
      return (
        schema
          .positive('Please enter a positive number.')
          // Max of 16 digits
          .max(9999999999999998, 'Please enter a valid number.')
          .required('This field is required')
      );
    return schema;
  }
}
export default new Validation(new PropertyAppraisedValueValidation());
