import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import loanFormModel from '../../form_models/loanFormModel';
import NumberOfEmployeesRangeRuleset from '../../business/ruleset/NumberOfEmployeesRangeRuleset';

const {
  numberOfEmployeesRange,
  businessTypeForProfitOrNonProfit,
  borrowerGrossRevenueLatest,
} = loanFormModel.formField;

export class NumberOfEmployeesRangeValidation implements ValidationSchema {
  public name = numberOfEmployeesRange.name;
  public label = numberOfEmployeesRange.label;
  public dependsOn: string[] = [
    businessTypeForProfitOrNonProfit.name,
    borrowerGrossRevenueLatest.name,
  ];
  public type = 'string';
  public required = {
    message: numberOfEmployeesRange.requiredErrorMsg,
    value: false,
  };
  public nullable = true;
  public ruleset = NumberOfEmployeesRangeRuleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const schema = args[this.dependsOn.length];
    const required = !!(result & RulesetResult.REQUIRED);

    if (required) {
      return schema.required(numberOfEmployeesRange.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new NumberOfEmployeesRangeValidation());
