import { RelatedParty } from '../../../../../../../../../types/api';
import { getTaxYears } from '../../../../../../components/loan_application/utils/helpers';
import {
  LIMITS,
  MAX_NUMBER_INPUT_VALUE,
  NUMBER_FORMATS,
  RENT_OWN,
  APPLICATION_BORROWER_BUSINESS_STRUCTURE,
  SF_MAX_CHAR_LIMITS,
  UNITED_STATES,
  YES_NO,
  BUSINESS_OWNERSHIP_STATUS_OPTIONS,
  BUSINESS_OWNERSHIP_STATUS_EXCLUSIVE_OPTIONS,
  NUMBER_OF_EMPLOYEES_RANGE_OPTIONS,
  MARKET_ESTIMATION_VALUE_CHOICES,
  ETHNICITY_OPTIONS,
  ETHNICITY_OPTIONS_EXCLUSIVE,
  SUB_ETHNICITY_OPTIONS,
  RACE_OPTIONS,
  RACE_OPTIONS_EXCLUSIVE,
  SUB_RACE_OPTIONS,
  OTHER_RACE_TOOLTIPS,
} from '../../../../../../constants';
import loanFormModelOriginal, {
  taxDocuments as taxDocumentsOriginal,
} from '../../../../../../components/loan_application/form_models/loanFormModel';

const required = true as const;
const [currentYear, lastYear, twoYearsAgo, threeYearsAgo, fourYearsAgo] =
  getTaxYears();

const loanFormModel = {
  formField: {
    ...loanFormModelOriginal.formField,
    borrowerEIN: {
      name: 'borrower_tin',
      label: 'Employer Identification Number (EIN)',
      requiredErrorMsg: 'EIN is required',
      getRequired: (borrowerBusinessStructure: string) =>
        borrowerBusinessStructure !== 'SOLE PROPRIETORSHIP',
      format: NUMBER_FORMATS.EIN,
      getTooltip: (borrowerLegalName: string) =>
        `Please provide the Employer Identification Number or Tax ID ${borrowerLegalName} uses to pay taxes. You will be able to find this on a previously filed tax return or your IRS form SS-4.`,
    },
    businessAddressIsSameAsSystemProperty: {
      name: 'sys_prop_address_is_same_as_business' as const,
      getLabel: (solarSystemLocationAddress: string) =>
        `Business Address is ${solarSystemLocationAddress}`,
      choices: YES_NO,
      required,
    },
    mailingAddressSameAsBusinessPicklist: {
      name: 'mailing_address_same_as_bussiness' as const,
      getLabel: (businessAddress: string) =>
        `Mailing Address is ${businessAddress}`,
      choices: YES_NO,
      required,
    },
    systemPropertyAddress: {
      name: 'sys_prop_address',
      label: 'Street Address',
      placeholder: 'Enter street address',
      required,
      requiredErrorMsg:
        'Address of the property where the system will be installed is a required field',
      infoText:
        'Please enter the physical address of the property that the solar system will be installed on.',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    systemPropertyAddressCity: {
      name: 'sys_prop_address_city',
      label: 'City',
      placeholder: 'Enter city',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
      requiredErrorMsg:
        'City of the property where the system will be installed is a required field',
    },
    systemPropertyAddressState: {
      name: 'sys_prop_address_state',
      label: 'State',
      choices: UNITED_STATES,
      placeholder: 'Choose state',
      required,
      requiredErrorMsg:
        'State of the property where the system will be installed is a required field',
    },
    systemPropertyAddressZipCode: {
      name: 'sys_prop_address_zip_code',
      label: 'Zip Code',
      placeholder: 'Enter 5 digit zip code',
      format: NUMBER_FORMATS.ZIP,
      required,
      requiredErrorMsg:
        'Zip code of the property where the system will be installed is a required field',
    },
    systemPropertyAppraisedValue: {
      name: 'system_property_appraised_value' as const,
      label: 'Estimated market value of the property above',
      required,
    },
    systemPropertyEstimationMethod: {
      name: 'system_property_estimation_method' as const,
      label: 'How was the estimated market value above determined?',
      requiredErrorMsg: 'Field is required',
      choices: MARKET_ESTIMATION_VALUE_CHOICES,
      required,
    },
    principalIdDocument: {
      name: 'Principal ID' as const,
      label:
        'Government issued ID such as a State driver’s license or passport',
      getLabel: (principalName: string) =>
        `Upload a government-issued ID for ${principalName}`,
      required,
      infoText:
        "Provide a copy of a government issued form of identification such as a driver's license or passport for each beneficial owner and controlling party.",
      requiredErrorMsg: 'Copy of Government-Issued ID is required',
    },
    systemPropertyOwnershipStatus: {
      name: 'sys_prop_ownership_status_picklist' as const,
      getLabel: (borrowerLegalName: string) =>
        `Does ${borrowerLegalName} own or lease the property above?`,
      required,
      requiredErrorMsg: 'Ownership Status is required',
      choices: [
        {
          value: 'OWNED',
          label: 'Own',
        },
        {
          value: 'LEASED',
          label: 'Lease',
        },
      ],
    },
    systemPropertyLegalNameOfOwner: {
      name: 'system_property_legal_name_of_owner' as const,
      label: 'Enter the name of the owner of the property.',
      tooltip:
        'Enter the legal name of the individual or entity that owns the property where the system will be installed. We will not reach out to them until we receive your consent.',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
      requiredErrorMsg: 'This field is required',
    },
    systemPropertyLandlordConsent: {
      name: 'sys_prop_landlord_consent_confirmation',
      getLabel: (borrowerLegalName: string) =>
        `Has ${borrowerLegalName} agreed to the installation of the solar system on the property?`,
      infoText:
        'Once approved for the loan, we will require your landlord to provide their consent in writing as a condition of loan funding.',
      getTooltip: (
        borrowerLegalName: string,
        propertyLegalNameOfOwner: string
      ) =>
        `If ${borrowerLegalName} is approved for the loan, we will require ${propertyLegalNameOfOwner} to provide their consent in writing as a condition of loan funding.`,
      choices: YES_NO,
      required,
      requiredErrorMsg: 'Landlord consent information is required',
    },
    systemPropertyOwnerAffiliatedWithBorrower: {
      name: 'sys_prop_owner_affiliated_with_borrower',
      getLabel: (borrowerLegalName: string, propertyLegalNameOfOwner: string) =>
        `Is ${propertyLegalNameOfOwner} related to ${borrowerLegalName}?`,
      choices: YES_NO,
      getTooltip: (
        borrowerLegalName: string,
        propertyLegalNameOfOwner: string
      ) =>
        `${borrowerLegalName} and ${propertyLegalNameOfOwner} are related if they are owned or controlled by one or more of the same individual(s) or entity(s).`,
      required,
    },
    systemPropertyLeaseExpirationYear: {
      name: 'system_property_lease_expiration_year' as const,
      label: 'Year of lease expiration',
      format: NUMBER_FORMATS.YEAR,
      tooltip:
        'Lease term should be at least 80% of the loan term, with the exception of major property modifications.',
      lease80percentLoanTermWarning:
        'We ordinarily require the remaining lease length to be 80% of the loan term. A member of our underwriting team will reach out to you upon submission regarding your particular situation.',
      required,
    },
    systemPropertyCurrentlyPayingMortgage: {
      name: 'borrower_is_currently_paying_mortgage' as const,
      label: `Is there a mortgage outstanding for the property above?`,
      required,
      choices: YES_NO,
    },
    borrowerBusinessStructure: {
      name: 'borrower_structure' as const,
      label: 'Business Structure',
      choices: [
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.LLC,
          label: 'LLC',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.LLP,
          label: 'LLP',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.SCORP,
          label: 'S-Corp',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.CCORP,
          label: 'C-Corp',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.GENERAL_PARTNERSHIP,
          label: 'General Partnership',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP,
          label: 'Sole Proprietorship',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.NON_PROFIT,
          label: 'Non-Profit',
        },
        {
          value: APPLICATION_BORROWER_BUSINESS_STRUCTURE.HOA,
          label: 'HOA',
        },
      ],
      required,
      requiredErrorMsg: 'Business Structure is required',
    },
    borrowerGrossRevenueLatest: {
      name: 'borrower_gross_revenue_latest' as const,
      label: 'Last Year Annual Gross Revenue',
      required,
      requiredErrorMsg: 'Gross Business Revenue is required',
      tooltip:
        'What was the gross annual revenue of the business applying for credit in its last full fiscal year? Gross annual revenue is the amount of money the business earned before subtracting taxes and other expenses. You may provide gross annual revenue calculated using any reasonable method.',
    },
    borrowerBusinessEstablishedYear: {
      name: 'business_established_year' as const,
      label: 'Business Established Year',
      required,
      format: NUMBER_FORMATS.YEAR,
    },
    borrowerYearsManagementInPlace: {
      name: 'years_management_in_place' as const,
      label: 'How many years has the present management been in place?',
      getTooltip: (borrowerLegalName: string) =>
        `Please provide the tenure of the present management of ${borrowerLegalName}.`,
      required,
      max: LIMITS.MAX_YEARS_ESTABLISHED,
    },
    borrowerManagementExperienceYears: {
      name: 'management_years_of_industry_experience',
      getLabel: (industry: string) =>
        `How many years of experience related to ${industry} does the longest-tenured management member have?`,
      getTooltip: (borrowerLegalName) =>
        `Please enter the number of years that ${borrowerLegalName}'s longest-tenured management member has been working in ${borrowerLegalName}'s industry.`,
      max: 99,
      required,
    },
    borrowerLinesOfBusiness: {
      name: 'borrower_industry_lines_of_business',
      getLabel: (borrowerLegalName: string) =>
        `Describe ${borrowerLegalName}'s business`,
      getTooltip: (borrowerLegalName: string) =>
        `Briefly describe what line of business ${borrowerLegalName} is in and how ${borrowerLegalName} generates revenue.`,
      required,
      requiredErrorMsg: 'Primary business activity is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    businessOwnershipStatus: {
      name: 'business_ownership_status',
      label:
        'What is your business ownership status? (check one or more of the options below)',
      choices: BUSINESS_OWNERSHIP_STATUS_OPTIONS,
      exclusiveOptions: BUSINESS_OWNERSHIP_STATUS_EXCLUSIVE_OPTIONS,
      required,
      requiredErrorMsg: 'Select at least one option',
      tooltip:
        'Minority means Hispanic or Latino, American Indian or Alaska Native, Asian, Black or African American, or Native Hawaiian or Other Pacific Islander. A multi-racial or multi-ethnic individual is a minority for this purpose.',
    },
    numberOfEmployeesRange: {
      name: 'number_of_employees_range',
      label: 'Number of Employees Range',
      choices: NUMBER_OF_EMPLOYEES_RANGE_OPTIONS,
      required,
      requiredErrorMsg: 'This field is required',
    },
    principalTrustYearEstablished: {
      name: 'year_trust_was_established',
      label: 'Trust Established Year',
      tooltip:
        'Please provide the year when the fully executed final trust agreement was filed.',
      format: NUMBER_FORMATS.YEAR,
      required,
    },
    principalIsUSCitizen: {
      name: 'is_us_citizen' as const,
      getLabel: (
        principal_first_name: string,
        principal_last_name: string,
        index: number
      ) =>
        principal_first_name && principal_last_name
          ? `Is ${principal_first_name} ${principal_last_name} a US citizen or permanent resident?`
          : `Is Principal ${index} a US citizen or permanent resident?`,
      choices: YES_NO,
      requiredErrorMsg: 'Answer is required',
      required,
    },
    principalPercentOwned: {
      name: 'percent_ownership',
      getLabel: (businessName: string) => `Percentage Owned of ${businessName}`,
      required,
      requiredErrorMsg: 'Percentage Owned is required',
      tooltip:
        'If the principal does not have ownership, such as a non-profit, enter zero for ownership percentage.',
      max: 100,
    },
    propertyOwnerPercentOwned: {
      name: 'property_owner_percent_ownership',
      getLabel: (propertyOnwer: string) =>
        `Percentage Owned of ${propertyOnwer}`,
      required,
      requiredErrorMsg: 'Percentage Owned is required',
      max: 100,
    },
    propertyCompanyTenantMonthlyRentPayment: {
      name: 'prop_com_tenant_monthly_rent_payment',
      label: "Tenant's Monthly Rent",
      required,
    },
    applicantSameAsPrincipal: {
      name: 'applicant_same_as_principal' as const,
      getLabel: (firstName: string, lastName: string) =>
        firstName && lastName
          ? `Confirm you are ${firstName} ${lastName}`
          : `Confirm you are Principal`,
      choices: YES_NO,
      required,
      requiredErrorMsg: 'Choice is required',
    },
    principalRentOrOwn: {
      name: 'rent_or_own',
      getLabel: (firstName: string, lastName: string) =>
        firstName && lastName
          ? `Does ${firstName} ${lastName} rent or own their residence?`
          : `Does Principal rent or own their residence?`,
      required,
      requiredErrorMsg: 'Choice is required',
      choices: RENT_OWN,
    },
    principalRent: {
      name: 'monthly_rent',
      label: 'Monthly Rent',
      getRequired: (
        isPersonalGuarantor: boolean,
        rentOrOwn: RelatedParty['rent_or_own']
      ) => isPersonalGuarantor && rentOrOwn == 'Rent',
      required,
      requiredErrorMsg: 'Monthly rent is required (Enter 0 if none)',
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
    },
    principalMortgagePayment: {
      name: 'mortgage_payment',
      label: 'Monthly Mortgage Payment',
      tooltip: 'Combined monthly interest and principal payment.',
      getRequired: (
        isPersonalGuarantor: boolean,
        rentOrOwn: RelatedParty['rent_or_own']
      ) => isPersonalGuarantor && rentOrOwn == 'Own',
      required,
      requiredErrorMsg: 'Mortgage payment is required (Enter 0 if none)',
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
    },
    principalEthnicity: {
      name: 'ethnicity',
      label: 'What is your ethnicity? (check one or more)',
      choices: ETHNICITY_OPTIONS,
      required,
      requiredErrorMsg: 'Select at least one option',
      exclusiveOptions: ETHNICITY_OPTIONS_EXCLUSIVE,
    },
    principalSubEthnicity: {
      name: 'sub_ethnicity',
      label: 'Sub Ethnicity',
      choices: SUB_ETHNICITY_OPTIONS,
      required,
      requiredErrorMsg: 'Select at least one option',
    },
    principalOtherEthnicity: {
      name: 'other_ethnicity',
      label: 'Other Ethnicity',
      required,
      requiredErrorMsg: 'This field is required',
      tooltip:
        'Please specify your origin, for example, Argentinean, Colombian, Dominican, Nicaraguan, Salvadoran, Spaniard, and so on',
    },
    principalSexGender: {
      name: 'sex_gender',
      label: 'What is your sex/gender? (please specify)',
      required,
      requiredErrorMsg: 'This field is required',
      optOutText: 'I do not wish to provide my sex/gender',
    },
    principalRace: {
      name: 'race',
      label: 'What is your race? (check one or more)',
      choices: RACE_OPTIONS,
      exclusiveOptions: RACE_OPTIONS_EXCLUSIVE,
      required,
      requiredErrorMsg: 'Select at least one option',
    },
    principalSubRace: {
      name: 'sub_race',
      label: 'What is your sub-race? (check one or more)',
      choices: SUB_RACE_OPTIONS,
      required,
      requiredErrorMsg: 'Select at least one option',
      tooltip: OTHER_RACE_TOOLTIPS,
    },
    principalOtherRace: {
      name: 'other_race',
      label: 'Other Race',
      required,
      requiredErrorMsg: 'This field is required',
    },
    propertyCompanyUploadRentRollOrProvideTenantInfo: {
      name: 'system_power_rent_roll_or_tenant_info' as const,
      label: 'How would you like to provide tenant information?',
      choices: [
        {
          label: 'Upload rent roll',
          value: 'Upload rent roll',
        },
        {
          label: 'Enter information for a single tenant',
          value: 'Enter information for a single tenant',
        },
      ],
      required,
    },
    companyDocuments: {
      ...loanFormModelOriginal.formField.companyDocuments,
      pgMortgageStatement: {
        name: 'Mortgage Statement' as const,
        getLabel: (address: string, required: boolean) =>
          `Please upload the most recent mortgage statement for the property at ${address} ${
            required ? '' : ' (Optional)'
          }. `,
        tooltip:
          'The mortgage statement should be from the institution that issued your mortgage and contain the monthly payment, the payment due date, interest rate, detail how the payment is allocated between principal, interest, taxes, insurance and other obligations, and list the property address.',
        getRequired: (mortgageStatementRequired: boolean) =>
          mortgageStatementRequired === true,
        required,
        requiredErrorMsg: 'Mortgate Statement is required',
      },
      propertyCompanyRentRoll: {
        name: 'Property Company Rent Roll' as const,
        getLabel: (borrowerLegalName: string) =>
          `Rent Roll (including each of ${borrowerLegalName}'s tenants)`,
        tooltip:
          "Must include each tenant's name, move-in date, current lease term, renewal term, monthly rent payment, and confirmation that the tenant has made their payments in a satisfactory manner.",
        required,
      },
      nonProfitBylawsCopy: {
        name: 'Non-Profit Bylaws' as const,
        label: 'Organization Bylaws',
        required,
      },
      nonProfitBoardResolution: {
        name: 'Non-Profit Board Resolution' as const,
        label: 'Board Authorization to Borrow',
        required,
      },
      religiousOrganizationAttendance: {
        name: 'Religious Organization Attendance' as const,
        label: 'Organization Attendance',
        tooltip: 'Attendance by month over the past 36 months',
        required,
      },
      religiousOrganizationDonations: {
        name: 'Religious Organization Donations' as const,
        label: 'Donations or Member Dues',
        tooltip:
          'Donations / tithes (“Giving Units”) by month by contributor over the past 36 months (anonymized)',
        required,
      },
    },
    hoaDocuments: {
      ...loanFormModelOriginal.formField.hoaDocuments,
      memberAssessmentPolicy: {
        name: 'Member Assessment Policy' as const,
        label: 'Member Assessment Policy',
        tooltip:
          'Policy detailing how operating and reserve assessments are calculated and how revenue and expenses are recognized across operating and reserve accounts.',
      },
      priorYearBudgets: {
        name: 'Operating and Reserve Budgets' as const,
        label: `Operating and Reserve Budgets for ${currentYear - 1}.`,
        required,
      },
      currentOccupantLedger: {
        name: 'Current Occupant Ledger' as const,
        label: 'Current Occupant Ledger',
        getTooltip: (borrowerLegalName: string) =>
          `For the privacy of your occupants, please provide unit numbers only – no names), their tenure with ${borrowerLegalName}, and the current status of dues payments (current, past due).`,
        required,
      },
      hoaBylaws: {
        name: 'HOA Bylaws' as const,
        label: 'HOA Bylaws',
        tooltip:
          'If not currently available, this can be submitted at a later time',
      },
    },
    solarSystemDocuments: {
      ...loanFormModelOriginal.formField.solarSystemDocuments,
      // Lease documents
      leaseOfTheSystemProperty: {
        name: 'Lease of the System Property' as const,
        getLabel: (propertyAddress: string) =>
          `Please upload the current lease for the system property ${
            propertyAddress ? `(${propertyAddress})` : ''
          }.`,
        tooltip:
          'The lease must be for the exact property where the system will be installed, whether a building, roof, or plot of land. If the lease is embedded within a Power Purchase Agreement, you may upload the Power Purchase Agreement.',
        required,
      },
    },
  },
};

export const taxDocuments = {
  ...taxDocumentsOriginal,
  financialStatementLastYearQ3: {
    name: 'Financial Statement Last Year Q3' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${lastYear} financial statements (P&L and balance sheet) covering at a minimum ${lastYear} Q3 year-to-date.`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
  },
  financialStatementQ1: {
    name: 'Financial Statement Q1' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${currentYear} year-to-date financial statements (P&L and balance sheet) up to ${currentYear} Q1.`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
  },
  taxReturns4Year: {
    name: 'Tax Returns 4 Years Ago' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${fourYearsAgo} tax return.`,
    getTooltip: (borrowerLegalName: string) =>
      `Please provide a copy of ${borrowerLegalName}'s tax return filed with the IRS for the ${fourYearsAgo} tax year.`,
  },
};

// add (Optional) to field labels that are not required
Object.keys(loanFormModel.formField).forEach((key) => {
  if (
    !loanFormModel.formField[key]?.required &&
    !loanFormModel.formField[key]?.getRequired &&
    loanFormModel.formField[key]?.label &&
    !loanFormModel.formField[key]?.label.includes('Optional')
  ) {
    loanFormModel.formField[key].label =
      loanFormModel.formField[key].label + ' (Optional)';
  }
});

export default loanFormModel;

function extractNames(obj: any): string[] {
  let names: string[] = [];
  for (const key in obj) {
    if (obj[key]?.name) {
      names.push(obj[key].name);
    }
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      names = names.concat(extractNames(obj[key]));
    }
  }
  return names;
}

export const allFieldNames = [
  ...extractNames(loanFormModel),
  ...extractNames(taxDocuments),
];
