import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { borrowerBusinessStructure },
} = loanFormModel;

export class BorrowerBusinessStructureValidation implements ValidationSchema {
  public name: string = borrowerBusinessStructure.name;
  public label: string = borrowerBusinessStructure.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: borrowerBusinessStructure.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
}
export default new Validation(new BorrowerBusinessStructureValidation());
