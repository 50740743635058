import { RACE, SUB_RACE_OPTIONS } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { principalSubRace, principalOtherRace } = loanFormModel.formField;

class HasOtherRaceAddedForRace implements Rule {
  private principalId: any;
  private race: string;

  public setPrincipalId(id: any) {
    this.principalId = id;
    return this;
  }

  private getPrincipal(data: Partial<ApplicationFormValues>) {
    return data.principals?.find(
      (principal) => principal.id === this.principalId
    );
  }

  public setRace(race: string) {
    this.race = race;
    return this;
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const principal = this.getPrincipal(application);
    const subRace = principal?.[principalSubRace.name] || [];
    const otherRace = principal?.[principalOtherRace.name] || {};

    if (!subRace.length || !this.race) return false;

    switch (this.race) {
      case RACE.AIAN: {
        const value = otherRace[RACE.AIAN];
        return value?.trim() !== '';
      }
      case RACE.ASIAN: {
        const subRace = principal?.[principalSubRace.name] || [];
        const hasOtherSelected = subRace.includes(
          SUB_RACE_OPTIONS[this.race].OTHER
        );
        const otherRaceValue =
          principal?.[principalOtherRace.name]?.[RACE.ASIAN];
        return hasOtherSelected && otherRaceValue?.trim() !== '';
      }
      case RACE.BAA: {
        const subRace = principal?.[principalSubRace.name] || [];
        const hasOtherSelected = subRace.includes(
          SUB_RACE_OPTIONS[this.race].OTHER
        );
        const otherRaceValue = principal?.[principalOtherRace.name]?.[RACE.BAA];
        return hasOtherSelected && otherRaceValue?.trim() !== '';
      }
      case RACE.NHPI: {
        const subRace = principal?.[principalSubRace.name] || [];
        const hasOtherSelected = subRace.includes(
          SUB_RACE_OPTIONS[this.race].OTHER
        );
        const otherRaceValue =
          principal?.[principalOtherRace.name]?.[RACE.NHPI];
        return hasOtherSelected && otherRaceValue?.trim() !== '';
      }
      default:
        return false;
    }
  }
}

export default new HasOtherRaceAddedForRace();
