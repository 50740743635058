import { IsAnnualGrossRevenue, IsBusinessEstablishedMoreThan } from '.';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Rule } from '../../interfaces/Rule';
import IsAgriculture from './IsAgriculture';
import IsExpressLoan from './IsExpressLoan';
import IsExpressPlusLoan from './IsExpressPlusLoan';

export class PrincipalIsPGMustBeYes implements Rule {
  public evaluate(data: Partial<ApplicationFormValues>): boolean {
    const isExpress = IsExpressLoan.evaluate(data);
    const isExpressPlus = IsExpressPlusLoan.evaluate(data);
    const isAgriculture = IsAgriculture.evaluate(data);
    const establishedFiveOrMoreYearsAgo =
      IsBusinessEstablishedMoreThan.setYears(4).evaluate(data);
    const annualGrossIsGreaterThanOrEqualTo =
      IsAnnualGrossRevenue.isGreaterThanOrEqualTo();

    if (
      isExpress &&
      establishedFiveOrMoreYearsAgo &&
      annualGrossIsGreaterThanOrEqualTo.setAmount(5_000_000).evaluate(data)
    )
      return false;
    else if (
      isExpressPlus &&
      establishedFiveOrMoreYearsAgo &&
      annualGrossIsGreaterThanOrEqualTo.setAmount(10_000_000).evaluate(data)
    )
      return false;

    if (!isAgriculture && !isExpress && !isExpressPlus) return false;

    return true;
  }
}

export default new PrincipalIsPGMustBeYes();
