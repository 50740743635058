import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  RulesetResult,
  IsBusinessEstablishedMoreThan,
  isExpressLoan,
  IsBetweenJanAndMidFeb,
  IsBetweenMidAprilMidMay,
} from '../rules';
import IsBetweenMidFebMidApril from '../rules/IsBetweenMidFebMidApril';

class TR4YearsAgoRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const isExpress = isExpressLoan.evaluate(data);
    const hasApplicantFiledTR = HasApplicantFiledTR.evaluate(data);
    // Express Loan always hide this field (03/11/2025)
    if (isExpress || !hasApplicantFiledTR) return RulesetResult.HIDDEN;

    if (IsBetweenMidAprilMidMay.evaluate(data)) return RulesetResult.OPTIONAL;

    const isBetweenJanAndMidApril = [
      IsBetweenJanAndMidFeb,
      IsBetweenMidFebMidApril,
    ].some((Is) => Is.evaluate(data));

    const isBusinessEstablishedMoreThan4YearsAgo =
      IsBusinessEstablishedMoreThan.setYears(4).evaluate(data);

    if (isBetweenJanAndMidApril)
      return isBusinessEstablishedMoreThan4YearsAgo
        ? RulesetResult.REQUIRED
        : RulesetResult.OPTIONAL;

    return RulesetResult.HIDDEN;
  };
}

export default new TR4YearsAgoRuleset();
