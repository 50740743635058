import { Application } from '../../../../../types/api';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import {
  defaultApplicantName,
  genericRequiredFieldError,
} from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import { isPropertyCompany, isCoreOrExpressPlusLoan } from './helpers';

const {
  systemPowerCompanyType,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  loanCategory,
} = loanFormModel.formField;

export class PropertyCompanyUploadRentRollOrProvideTenantInfoValidation
  implements ValidationSchema
{
  public name = propertyCompanyUploadRentRollOrProvideTenantInfo.name;
  public label =
    propertyCompanyUploadRentRollOrProvideTenantInfo.getLabel(
      defaultApplicantName
    );
  public dependsOn: string[] = [systemPowerCompanyType.name, loanCategory.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    systemPowerCompanyType: Application['solar_system_power_company_type'],
    loanCategory: Application['loan_category'],
    schema
  ) {
    const required =
      isPropertyCompany(systemPowerCompanyType) &&
      isCoreOrExpressPlusLoan(loanCategory);

    if (!required) return schema.notRequired();
    return schema.required(genericRequiredFieldError);
  }
}
export default new Validation(
  new PropertyCompanyUploadRentRollOrProvideTenantInfoValidation()
);
