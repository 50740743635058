import loanFormModel from '../../form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import * as Yup from 'yup';

const {
  formField: {
    systemPropertyOwnershipStatus,
    systemPropertyLeaseExpirationYear,
  },
} = loanFormModel;

export class PropertyLeaseExpirationYearValidation implements ValidationSchema {
  public name: string = systemPropertyLeaseExpirationYear.name;
  public label = '';
  public dependsOn: string[] = [systemPropertyOwnershipStatus.name];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(ownershipType: string, schema: Yup.NumberSchema) {
    if (ownershipType === 'LEASED')
      return schema
        .min(new Date().getFullYear(), 'Lease cannot expire in the past')
        .required('This field is required');
    return schema;
  }
}
export default new Validation(new PropertyLeaseExpirationYearValidation());
