import loanFormModel from '../../pages/dashboard/loan_detail/flows/NewWelcomeFlow/form_models/loanFormModel';
import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
const {
  formField: { borrowerLinesOfBusiness },
} = loanFormModel;

export class BorrowerLinesOfBusinessValidation implements ValidationSchema {
  public name: string = borrowerLinesOfBusiness.name;
  public label: string = borrowerLinesOfBusiness.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: 'Primary business activity is required',
    value: true,
  };
  public nullable = true;
}
export default new Validation(new BorrowerLinesOfBusinessValidation());
