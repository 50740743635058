import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../form_models/loanFormModel';
import { RulesetResult } from '../../business/rules';
import PrincipalIsPGMustBeYes from '../../business/rules/PrincipalIsPGMustBeYes';
import PrincipalIsPGRuleset from '../../business/ruleset/PrincipalIsPGRuleset';

const {
  formField: { principalIsPG, loanCategory },
} = loanFormModel;

export class PrincipalIsPGValidation implements ValidationSchema {
  public name = principalIsPG.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public typeError = {
    message: 'Choice is required',
    value: true,
  };

  public when(schema) {
    return schema.test({
      name: 'principalIsPGTest',
      test: function (value) {
        const data = this.from[1].value;
        const result = PrincipalIsPGRuleset.evaluate(data);
        // Changing isPGValue to LoanCategory.EXPRESS fixed a bug which made the field not required if
        // the required 51% was already achieved

        if (result & RulesetResult.REQUIRED) {
          if (PrincipalIsPGMustBeYes.evaluate(data))
            return (
              value === 'Yes' ||
              this.createError({ message: 'Principal needs to be a Guarantor' })
            );
          else
            return (
              !!value || this.createError({ message: 'Choice is required' })
            );
        }
        return true;
      },
    });
  }
}

export default new Validation(new PrincipalIsPGValidation());
