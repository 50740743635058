import { INTERVAL_DATES } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Rule } from '../../interfaces/Rule';

class IsBetweenMidAprilMidMay implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    return INTERVAL_DATES().MidAprilMidMay;
  }
}

export default new IsBetweenMidAprilMidMay();
