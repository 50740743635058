import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalIsPG, applicantSameAsPrincipal, __asapCount },
} = loanFormModel;

export class ApplicantSameAsPrincipalValidation implements ValidationSchema {
  public name = applicantSameAsPrincipal.name;
  public label = '';
  public dependsOn: string[] = [principalIsPG.name, __asapCount.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public typeError = {
    message: 'Choice is required',
    value: true,
  };

  public when(principalIsPG: string, asapCount: number, schema) {
    return schema.test({
      name: 'principalSameAsApplicantTest',
      test: function (value) {
        if (principalIsPG === 'Yes' && asapCount === 0) {
          if (!value) {
            return this.createError({
              message: 'Choice is required',
            });
          }
          return true; // The validation passes if value is not empty
        }
        return true; // For all other cases, the field is optional
      },
    });
  }
}

export default new Validation(new ApplicantSameAsPrincipalValidation());
