import { INTERVAL_DATES } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Rule } from '../../interfaces/Rule';

class IsBetweenMidNovAndDec implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    return INTERVAL_DATES().MidNovDec;
  }
}

export default new IsBetweenMidNovAndDec();
