import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';
import { SUB_RACE_OPTIONS } from '../../../../../../../constants';

const { principalSubRace } = loanFormModel.formField;

class HasOptionsAddedInSubRaceFromRace implements Rule {
  private principalId: any;
  private race: string;

  public setPrincipalId(id: any) {
    this.principalId = id;
    return this;
  }

  private getPrincipal(data: Partial<ApplicationFormValues>) {
    return data.principals?.find(
      (principal) => principal.id === this.principalId
    );
  }

  public setRace(race: string) {
    this.race = race;
    return this;
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const principal = this.getPrincipal(application);
    const subRace = principal?.[principalSubRace.name] || [];

    if (!subRace.length || !this.race) return false;

    const containsOptionsForRace =
      subRace.some((selectedSubRace) =>
        Object.values(SUB_RACE_OPTIONS[this.race]).includes(selectedSubRace)
      ) ?? false;

    return containsOptionsForRace;
  }
}

export default new HasOptionsAddedInSubRaceFromRace();
