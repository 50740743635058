import { genericRequiredFieldError } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import { RulesetResult } from '../../business/rules';
import { ApplicantFiledTRLastYearRuleset } from '../../business/ruleset';
import { taxDocuments } from '../../form_models/loanFormModel';

const { applicantFiledLastYearTaxReturns, applicantFiledTaxReturnsInThePast } =
  taxDocuments;

export class ApplicantFiledLastYearTaxReturnsValidation
  implements ValidationSchema
{
  public name = applicantFiledLastYearTaxReturns.name;
  public label = '';
  public dependsOn: string[] = [applicantFiledTaxReturnsInThePast.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;
  public ruleset = ApplicantFiledTRLastYearRuleset;

  public when(...args: any[]) {
    const result: RulesetResult = args.pop();
    const schema = args[this.dependsOn.length];
    const required = !!(result & RulesetResult.REQUIRED);
    if (required) return schema.required(genericRequiredFieldError);
    return schema.notRequired();
  }
}
export default new Validation(new ApplicantFiledLastYearTaxReturnsValidation());
