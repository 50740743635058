import HasApplicantFiledTR from './HasApplicantFiledTR';
import IsBetweenJanAndMidFeb from './IsBetweenJanAndMidFeb';
import IsBetweenMidAprilMidMay from './IsBetweenMidAprilMidMay';
import IsBetweenMidMayMidAug from './IsBetweenMidMayMidAug';
import IsBetweenMidAugMidOct from './IsBetweenMidAugMidOct';
import IsBetweenMidOctMidNov from './IsBetweenMidOctMidNov';
import IsBetweenMidNovAndDec from './IsBetweenMidNovAndDec';
import isExpressLoan from './IsExpressLoan';
import HasApplicantFiledTRLastYear from './HasApplicantFiledTRLastYear';
import IsBusinessEstablishedMoreThan from './IsBusinessEstablishedMoreThan';
import IsForProfit from './IsForProfit';
import IsAnnualGrossRevenue from './IsAnnualGrossRevenue';
import IsExpressPlusLoan from './IsExpressPlusLoan';
import IsHispanicOrLatino from './IsHispanicOrLatino';
import IsOtherEthnicity from './IsOtherEthnicity';
import IsAIAN from './IsAIAN';
import IsAsian from './IsAsian';
import IsBlackOrAfricanAmerican from './IsBlackOrAfricanAmerican';
import IsNHPI from './IsNHPI';
import HasOptionsAddedInSubRaceFromRace from './HasOptionsAddedInSubRaceFromRace';
import HasOtherRaceAddedForRace from './HasOtherRaceAddedForRace';

export enum RulesetResult {
  HIDDEN = 0x0,
  OPTIONAL = 0x1,
  REQUIRED = 0x2,
  VISIBLE = 0x3,
}

export {
  HasApplicantFiledTR,
  IsBetweenJanAndMidFeb,
  isExpressLoan,
  IsBetweenMidAprilMidMay,
  IsBetweenMidMayMidAug,
  IsBetweenMidAugMidOct,
  IsBetweenMidOctMidNov,
  IsBetweenMidNovAndDec,
  HasApplicantFiledTRLastYear,
  IsBusinessEstablishedMoreThan,
  IsForProfit,
  IsAnnualGrossRevenue,
  IsExpressPlusLoan,
  IsHispanicOrLatino,
  IsOtherEthnicity,
  IsAIAN,
  IsAsian,
  IsBlackOrAfricanAmerican,
  IsNHPI,
  HasOptionsAddedInSubRaceFromRace,
  HasOtherRaceAddedForRace,
};
