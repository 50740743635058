import { INTERVAL_DATES } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Rule } from '../../interfaces/Rule';

class IsBetweenMidFebMidApril implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    return INTERVAL_DATES().MidFebMidApril;
  }
}

export default new IsBetweenMidFebMidApril();
