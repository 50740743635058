import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import { IsExpressPlusLoan, RulesetResult } from '../rules';
import IsAgriculture from '../rules/IsAgriculture';
import IsExpressLoan from '../rules/IsExpressLoan';

export class PrincipalIsPgRuleset implements Ruleset {
  public evaluate(data: Partial<ApplicationFormValues>): RulesetResult {
    const isExpress = IsExpressLoan.evaluate(data);
    const isExpressPlus = IsExpressPlusLoan.evaluate(data);
    const isAgriculture = IsAgriculture.evaluate(data);
    if (isAgriculture || isExpress || isExpressPlus)
      return RulesetResult.REQUIRED;
    return RulesetResult.HIDDEN;
  }
}

export default new PrincipalIsPgRuleset();
