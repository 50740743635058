import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import { RulesetResult, IsForProfit, IsAnnualGrossRevenue } from '../rules';

class BusinessOwnershipStatusRuleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    if (
      IsForProfit.evaluate(data) &&
      IsAnnualGrossRevenue.isLessThan().setAmount(5_000_000).evaluate(data)
    )
      return RulesetResult.REQUIRED;

    return RulesetResult.HIDDEN;
  };
}

export default new BusinessOwnershipStatusRuleset();
