import { RELATED_PARTY_TYPES } from '../../../../../../../constants';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalType, principalIsUSCitizen, applicantSameAsPrincipal },
} = loanFormModel;

export class PrincipalIsUSCitizenValidation implements ValidationSchema {
  public name = principalIsUSCitizen.name;
  public label = principalIsUSCitizen.label;
  public dependsOn: string[] = [
    principalType.name,
    applicantSameAsPrincipal.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalType: string, applicantSameAsPrincipal: string, schema) {
    if (
      principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
      applicantSameAsPrincipal === 'Yes'
    ) {
      return schema.required(principalIsUSCitizen.requiredErrorMsg);
    }
    return schema.notRequired();
  }
}
export default new Validation(new PrincipalIsUSCitizenValidation());
