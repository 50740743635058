import {
  Application,
  LoanCategory,
} from '../../../../../../../../../../types/api';
import {
  defaultApplicantName,
  PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO,
} from '../../../../../../../constants';
import FileNameValidationSchema from '../../../../../../../validations/FileNameValidationSchema';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModelNew from '../../form_models/loanFormModel';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  companyDocuments: { propertyCompanyRentRoll },
  propertyCompanyUploadRentRollOrProvideTenantInfo,
} = loanFormModelNew.formField;

const { loanCategory } = loanFormModel.formField;
export class PropertyCompanyRentRollValidation implements ValidationSchema {
  public name = propertyCompanyRentRoll.name;
  public label = propertyCompanyRentRoll.getLabel(defaultApplicantName);
  public dependsOn: string[] = [
    propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    loanCategory.name,
  ];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };

  public when(
    propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
    loanCategory: Application['loan_category'],
    schema
  ) {
    const required =
      propertyCompanyUploadRentRollOrProvideTenantInfo ===
        PROPERTY_COMPANY_UPLOAD_RENT_ROLL_OR_PROVIDE_TENANT_INFO.UPLOAD_RENT_ROLL &&
      loanCategory != LoanCategory.EXPRESS_PROPCO;
    schema = FileNameValidationSchema(propertyCompanyRentRoll.name, required);
    if (required) schema = schema.required('This document is required');
    return schema;
  }
}
export default new Validation(new PropertyCompanyRentRollValidation());
