import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../../../../../../components/loan_application/form_models/loanFormModel';

const {
  formField: { principalLiquidAssets, principalIsPG, applicantSameAsPrincipal },
} = loanFormModel;

export class PrincipalLiquidAssetsValidation implements ValidationSchema {
  public name = principalLiquidAssets.name;
  public label = principalLiquidAssets.label;
  public dependsOn: string[] = [
    principalIsPG.name,

    applicantSameAsPrincipal.name,
  ];
  public type = 'number';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(principalIsPG: string, applicantSameAsPrincipal: string, schema) {
    if (principalIsPG === 'Yes' && applicantSameAsPrincipal === 'Yes') {
      return schema.required(principalLiquidAssets.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new PrincipalLiquidAssetsValidation());
