import { INTERVAL_DATES } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Rule } from '../../interfaces/Rule';

class IsBetweenMidMayMidAug implements Rule {
  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    return INTERVAL_DATES().MidMayMidAug;
  }
}

export default new IsBetweenMidMayMidAug();
