import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';
import loanFormModel from '../../form_models/loanFormModel';

const {
  formField: { systemPropertyAddressCity },
} = loanFormModel;

export class PropertyAddressCityValidation implements ValidationSchema {
  public name: string = systemPropertyAddressCity.name;
  public label: string = systemPropertyAddressCity.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: systemPropertyAddressCity.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
}
export default new Validation(new PropertyAddressCityValidation());
