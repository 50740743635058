import { defaultBorrowerLegalName } from '../../components/loan_application/form_models/constants';
import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { LIMITS } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { borrowerBusinessEstablishedYear },
} = loanFormModel;

export class BorrowerBusinessEstablishedYearValidation
  implements ValidationSchema
{
  public name = borrowerBusinessEstablishedYear.name;
  public label = borrowerBusinessEstablishedYear.getLabel(
    defaultBorrowerLegalName
  );
  public dependsOn: string[] = [];
  public type = 'date';
  public required = {
    message: 'This field is required',
    value: borrowerBusinessEstablishedYear.required,
  };
  public nullable = true;
  public typeError = { value: true, message: 'Please enter a valid date.' };
  public min = {
    value: LIMITS.MIN_YEAR_ALLOWED,
    message: 'Please enter a valid year.',
  };
  public max = {
    value: new Date().getFullYear() as any,
    message: 'Please enter a valid year.',
  };
}
export default new Validation(new BorrowerBusinessEstablishedYearValidation());
