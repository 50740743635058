import loanFormModel from '../../form_models/loanFormModel';
import { ValidationSchema } from '../../../../../../../validations/interfaces';
import { Validation } from '../../../../../../../validations/Validation';

const {
  formField: { systemPropertyAddress },
} = loanFormModel;

export class PropertyAddressValidation implements ValidationSchema {
  public name: string = systemPropertyAddress.name;
  public label: string = systemPropertyAddress.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: systemPropertyAddress.requiredErrorMsg,
    value: true,
  };
  public nullable = true;
}
export default new Validation(new PropertyAddressValidation());
