import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { APPLICATION_BORROWER_BUSINESS_STRUCTURE } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { borrowerTIN, borrowerBusinessStructure },
} = loanFormModel;

export class BorrowerTinValidation implements ValidationSchema {
  public name: string = borrowerTIN.name;
  public label: string = borrowerTIN.label;
  public dependsOn: string[] = [borrowerBusinessStructure.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(borrowerStructure: string, schema: Yup.StringSchema) {
    return borrowerStructure ===
      APPLICATION_BORROWER_BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP
      ? schema
      : schema
          .label(borrowerTIN.label)
          .min(9, 'Must be exactly 9 digits')
          .max(9, 'Must be exactly 9 digits')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required('EIN is required')
          .nullable();
  }
}
export default new Validation(new BorrowerTinValidation());
