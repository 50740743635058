import React, { memo, ReactNode, useState } from 'react';
import {
  InputAdornment,
  Typography,
  Box,
  Radio,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import StyledInput from '../inputs/StyledInput';
import type { StyledInputProps } from '../inputs/StyledInput';
import { Control, Controller, FieldValues } from 'react-hook-form';

type InputFieldProps<T extends FieldValues = any> = StyledInputProps & {
  control?: Control<T>;
  name: string;
  startAdornmentText?: string;
  endAdornmentText?: string;
  readonly?: boolean;
  maxLength?: number;
  counter?: boolean;
  touched?: boolean;
  isDisabled?: boolean;
  infoText?: string;
  includeOptOut?: boolean;
  optOutText?: string;
};

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const {
      control,
      name,
      isDisabled,
      infoText,
      startAdornmentText,
      endAdornmentText,
      readOnly,
      maxLength,
      counter,
      touched: defaultTouched,
      includeOptOut = false,
      optOutText = 'I do not wish to provide this information',
      ...rest
    } = props;

    let startAdornment: ReactNode | undefined = undefined;
    if (startAdornmentText) {
      startAdornment = (
        <InputAdornment position="start">{startAdornmentText}</InputAdornment>
      );
    }
    let endAdornment: ReactNode | undefined = undefined;
    if (endAdornmentText) {
      endAdornment = (
        <InputAdornment position="end">{endAdornmentText}</InputAdornment>
      );
    }

    const id = props.id ?? props.name;

    const [optedOut, setOptedOut] = useState(false);

    const handleOptOut = (
      fieldOnChange: (value: any) => void,
      currentValue: string
    ) => {
      const newOptedOutState = !optedOut;
      setOptedOut(newOptedOutState);

      // If the user opted out, set the value to the opt out text
      if (newOptedOutState && currentValue !== optOutText) {
        fieldOnChange(optOutText);
        // If the unchecks the opt out, we et the value to null
      } else if (!newOptedOutState && currentValue === optOutText) {
        fieldOnChange(null);
      }
    };

    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const { error, isTouched: fieldTouched } = fieldState;
          const touched = defaultTouched || fieldTouched;

          function _renderHelperText() {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  whiteSpace: 'nowrap',
                }}
                component="span"
              >
                <Typography
                  variant="body2"
                  flexGrow={0}
                  pt={0.375}
                  component="span"
                >
                  {counter &&
                    field.value &&
                    `${field.value?.length ?? 0}/${maxLength}`}
                </Typography>
                {touched && error?.message}
              </Box>
            );
          }
          return (
            <FormGroup
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
              }}
            >
              <StyledInput
                id={id}
                type="text"
                error={Boolean(touched && error)}
                helperText={_renderHelperText()}
                disabled={isDisabled || optedOut}
                InputProps={{
                  readOnly: readOnly,
                  endAdornment: endAdornment,
                  startAdornment: startAdornment,
                }}
                {...field}
                // If the user opted out, we display the empty input field, though the value is still set to the optOutText
                value={optedOut ? '' : field.value ?? ''}
                ref={ref}
                onChange={(e) => {
                  let value = e.target.value;
                  if (maxLength && value.length > maxLength)
                    value = value.slice(0, maxLength);
                  field.onChange(value);
                }}
                {...rest}
                onFocus={(e) => {
                  rest.onFocus?.(e); // Preserve additional onFocus from `...rest`
                  (field as any).onFocus?.(e); // Ensure RHF validation runs (Need to declare it as unknown to avoid type error. Typescript doesn't know that field is a Controller field which in fact has onFocus)
                }}
                onBlur={(e) => {
                  rest.onBlur?.(e); // Preserve additional onBlur from `...rest`
                  (field as any).onBlur?.(e); // Ensure RHF validation runs (Need to declare it as unknown to avoid type error. Typescript doesn't know that field is a Controller field which in fact has onBlur)
                }}
              />
              {includeOptOut && (
                <FormControlLabel
                  control={
                    <Radio
                      checked={optedOut}
                      onClick={() => handleOptOut(field.onChange, field.value)}
                    />
                  }
                  label={optOutText}
                />
              )}
            </FormGroup>
          );
        }}
      />
    );
  }
);

export default memo(InputField);
