import { SUB_ETHNICITY } from '../../../../../../../constants';
import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import loanFormModel from '../../form_models/loanFormModel';
import { Rule } from '../../interfaces/Rule';

const { principalSubEthnicity } = loanFormModel.formField;

class IsOtherEthnicity implements Rule {
  private principalId: any;

  public setPrincipalId(id: any) {
    this.principalId = id;
    return this;
  }

  private getPrincipal(data: Partial<ApplicationFormValues>) {
    return data.principals?.find(
      (principal) => principal.id === this.principalId
    );
  }

  public evaluate(application: Partial<ApplicationFormValues>): boolean {
    const principal = this.getPrincipal(application);

    const subEthnicity = principal?.[principalSubEthnicity.name] || [];
    return subEthnicity?.includes(SUB_ETHNICITY.OTHER) ?? false;
  }
}

export default new IsOtherEthnicity();
