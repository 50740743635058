import { ApplicationFormValues } from '../../../../LoanDetailTypes';
import { Ruleset } from '../../interfaces/Ruleset';
import {
  HasApplicantFiledTR,
  RulesetResult,
  isExpressLoan,
  IsBetweenMidNovAndDec,
} from '../rules';
import IsBusinessEstablishedEqualTo from '../rules/IsBusinessEstablishedEqualTo';
import IsBusinessEstablishedLessThan from '../rules/IsBusinessEstablishedLessThan';

class FinancialStatementQ3Ruleset implements Ruleset {
  public evaluate = (data: Partial<ApplicationFormValues>): RulesetResult => {
    const businessEstablishedTwoYearsAgo =
      IsBusinessEstablishedEqualTo.setYears(2).evaluate(data);
    const businessEstablishedLessThanTwoYearsAgo =
      IsBusinessEstablishedLessThan.setYears(2).evaluate(data);
    const isBetweenMidNovAndDec = IsBetweenMidNovAndDec.evaluate(data);
    const hasApplicantEverFiledTR = HasApplicantFiledTR.evaluate(data);
    if (
      (businessEstablishedTwoYearsAgo ||
        businessEstablishedLessThanTwoYearsAgo) &&
      !hasApplicantEverFiledTR
    ) {
      if (isBetweenMidNovAndDec)
        return businessEstablishedTwoYearsAgo
          ? RulesetResult.REQUIRED
          : RulesetResult.OPTIONAL;
      return RulesetResult.HIDDEN;
    }

    if (hasApplicantEverFiledTR && isExpressLoan.evaluate(data))
      return RulesetResult.HIDDEN;

    if (hasApplicantEverFiledTR && isBetweenMidNovAndDec)
      return RulesetResult.REQUIRED;

    return RulesetResult.HIDDEN;
  };
}

export default new FinancialStatementQ3Ruleset();
